//var autobahn = require('autobahn')
//import {autobahn} from './autobahn.js'
var autobahn = require('./autobahn.js');


export var RecordingHubClient = function ( ws_url, uuid, exploration_id ) {
	this.ws_url = ws_url;
	this.uuid = uuid;
	this.exploration_id = exploration_id;
	this.started = false;
	this.remainMilisecondsUpload = this.hourMilliseconds;
}

RecordingHubClient.prototype = {
	hourMilliseconds: 3600000,
	startRecording: function ( localStream ) {
		var self = this,
			conversation_websocket = new autobahn.Connection({
				url: this.ws_url,
				realm: "conversation",
                serializers: [new autobahn.serializer.MsgpackSerializer()]
        	});
        this.conversation_websocket = conversation_websocket;
        this.localStream = localStream
        conversation_websocket.onopen = function ( session ) {
        	self.session = session;
        	if(self.started) return;
        	self.started = true;
        	session.call("start_recording", [self.exploration_id, self.uuid]).then(function () {
        	    self.initRecordingSession( session, self.localStream );
        	});

        	session.subscribe(self.exploration_id + ".restart_recording", function ( args ) {
        		console.log('restart recording for uuid :', args);
        		if( args[0] === self.uuid ) {
        			if( self.localStreamRecorder.state === 'recording' ) {
						self.localStreamRecorder.stop();
					}
					self.localStreamRecorder = self.newLocalStreamRecorder( session, self.localStream );
        		}
        	});
        }
        conversation_websocket.open();
	},

	initRecordingSession: function ( session, localStream ) {
		this.onRecordingSessionStart( localStream );
		this.localStreamRecorder = this.newLocalStreamRecorder( session, localStream );
		this.localStreamRecorder.start(1000);
		this.session.publish(this.exploration_id + ".recording_status", [this.uuid, true]);
	},

	newLocalStreamRecorder: function ( session, localStream ) {
		var self = this,
			// videoBitsPerSecond = conversation_config.videoBitsPerSecond,
			// audioBitsPerSecond = conversation_config.audioBitsPerSecond,
			// bitsPerSecond = conversation_config.bitsPerSecond,
			recorderParams = {
				mimeType: 'video/webm;codecs=h264'
			};
		// if( videoBitsPerSecond ) { recorderParams.videoBitsPerSecond =  videoBitsPerSecond; }
		// if( audioBitsPerSecond ) { recorderParams.audioBitsPerSecond =  audioBitsPerSecond; }
		// if( bitsPerSecond ) { recorderParams.bitsPerSecond =  bitsPerSecond; }

		var localStreamRecorder = new MediaRecorder( localStream, recorderParams );

		localStreamRecorder.addEventListener('dataavailable', function ( e ) {
			//console.log( e.data );
			e.data.arrayBuffer().then(function ( videoArrayBuffer ) {
				self.remainMilisecondsUpload -= 1000;
				if( !self.remainMilisecondsUpload ) {
					self.uploadRecording( true );
					self.remainMilisecondsUpload = self.hourMilliseconds;
				}
				return session.call('new_recording_data', [new Uint8Array(videoArrayBuffer)]);
			}).then(function ( result ) {
				 if( !result ) {
					//self.pauseStreaming();
				}
			});
		});

		localStreamRecorder.addEventListener('close', function () {
			console.log('closed');
			//self.conversation_websocket.close();
		});
		return localStreamRecorder;
	},

	pauseRecording: function () {
		this.session.publish(this.exploration_id + ".recording_status", [this.uuid, false]);
		this.localStreamRecorder.pause();
	},

	resumeRecording: function () {
		if( this.localStreamRecorder.state === 'inactive' ) {
			this.localStreamRecorder.start(1000);
		}
		this.session.publish(this.exploration_id + ".recording_status", [this.uuid, true]);
		this.localStreamRecorder.resume();
	},

	getRecordingStatus: function ()  {

        if( this.localStreamRecorder ) {
            if( this.localStreamRecorder.state === 'recording' ) {
                return 1;
            } else {
                return 0;
            }
        } else {
            return -1;
        }
	},

	updateRecordingStatus: function ( status ) {
		if( this.localStreamRecording ) {
			if( this.localStreamRecorder.state === 'recording' ) {
				this.pauseRecording();
			} else {
				this.resumeRecording();
			}
		} else {
			this.startRecording( this.localStream );
		}

	},

	updateLocalStream: function ( localStream ) {
		if( this.localStreamRecorder ) {
			if ( this.localStreamRecorder.state === 'recording' ) {
				this.pauseRecording();
				this.localStreamRecorder.stop();
			}
			this.localStreamRecorder = this.newLocalStreamRecorder( this.session, localStream );
			this.uploadRecording();
		}
		this.localStream = localStream;
	},

	uploadRecording: function ( dontStop ) {
		var self = this;
		if( this.localStreamRecorder ) {
			if( this.localStreamRecorder.state === 'recording' ) {
				this.pauseRecording();
			}
			this.session.publish(this.exploration_id + ".recording_upload_status", [this.uuid, true]);
			this.session.call('upload_recording', [], {}, {receive_progress: true}).then(function() {
				self.localStreamRecorder = self.newLocalStreamRecorder( self.session, self.localStream );
				self.session.publish(self.exploration_id + ".recording_upload_status", [self.uuid, false]);
				if( dontStop ) {
					self.resumeRecording();
				}
			});
		}

	},

	createVideoElementFromStream: function ( stream ) {
      var videoElement = document.createElement('video');
      videoElement.setAttribute('autoplay', '');
      videoElement.setAttribute( 'data-stream-id', stream.id );
      videoElement.srcObject = stream;
      videoElement.muted = true;
      return videoElement;
    },


	onRecordingSessionStart: function ( localStream ) {

	}
}
