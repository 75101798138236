import {ConversationHubClient} from './conversation_hub_client'

  export var conversation_config = new Object()

$(document).on('turbolinks:load', function() {

  if ($(".video-in").length > 0) {

    var urlParams = new URLSearchParams(window.location.search);
    var video_conv_id = urlParams.get('video_conv_id');
    var display = urlParams.get('display');
    var video_params = urlParams.get('video_params');
    var width = urlParams.get('width');
    var height = urlParams.get('height');
    var frameRate = urlParams.get('frameRate');
    var identity = urlParams.get('identity');
    var local_mode = false

if (!video_conv_id) {
  video_conv_id = Math.floor(Math.random() * 100).toString();
}


    var videoParam = video_params ? conversation_config.video = {width: width, height: height, frameRate: frameRate} : conversation_config.video = true;


console.log(video_conv_id)

      var ws_url = "wss://server.remotesurvey.live:8080/ws";

		var conversationHubClient = new ConversationHubClient( ws_url, '', video_conv_id, true, local_mode, ConversationHubClient.prototype.SEND, identity, display );

  conversationHubClient.join();
  conversationHubClient.onLocalVideoStream = function ( localStream ) {
    var videoStreamEl = conversationHubClient.createVideoElementFromStream( localStream );
        videoStreamEl.setAttribute("id", "vid1");
    document.getElementById('video_wrapper').appendChild( videoStreamEl );
  }

  conversationHubClient.onLocalVideoStreamUpdate = function ( localStream ) {
    document.getElementById('vid1').srcObject = localStream;
  }

  document.getElementById( 'capture' ).addEventListener('click', function( e ) {
		var videoElement = document.getElementById('vid1'),
			canvas = document.createElement( 'canvas' );
		canvas.width  = videoElement.videoWidth;
		canvas.height = videoElement.videoHeight;
		canvas.getContext('2d').drawImage( videoElement, 0, 0, canvas.width, canvas.height );
		var link = document.createElement( 'a' );
		link.download = 'capture.png';
		canvas.toBlob(function ( blob ) {
			link.href = URL.createObjectURL( blob );
			link.click();
			console.log(link);
		}, 'image/png');

	});

  var cameraSelect = document.getElementById('camera_sources');
  navigator.mediaDevices.enumerateDevices().then(function ( deviceInfos ) {
    for (let i = 0; i !== deviceInfos.length; ++i) {
      const deviceInfo = deviceInfos[i];
        if (deviceInfo.kind === 'videoinput') {
          const option = document.createElement('option');
          option.id= 'element';
          option.value = deviceInfo.deviceId;
          option.text = deviceInfo.label || `camera ${videoSelect.length + 1}`;
            cameraSelect.appendChild(option);
        }
    }
  }).catch(function ( error ) {

  });
  document.getElementById('update_btn').addEventListener('click', function () {
    var cameraDeviceId = document.getElementById('camera_sources').value;
    const constraints = {
        video: {deviceId: cameraDeviceId ? {exact: cameraDeviceId} : undefined},
        audio: false
      };
      navigator.mediaDevices.getUserMedia(constraints).then(function ( localStream ) {
        conversationHubClient.updateLocalStream( localStream );
      });
  });
  var updatingDevices = false;
  navigator.mediaDevices.ondevicechange = function () {
    if( updatingDevices ) {
      return;
    }
    updatingDevices = true;
    var cameraSelect = document.getElementById('camera_sources');
    cameraSelect.innerHTML = "";
    navigator.mediaDevices.enumerateDevices().then(function ( deviceInfos ) {
      for (let i = 0; i !== deviceInfos.length; ++i) {
        const deviceInfo = deviceInfos[i];
          if (deviceInfo.kind === 'videoinput') {
            const option = document.createElement('option');
            option.id= 'element';
            option.value = deviceInfo.deviceId;
            option.text = deviceInfo.label || `camera ${videoSelect.length + 1}`;
              cameraSelect.appendChild(option);
          }
      }
      updatingDevices = false;
    }).catch(function ( error ) {

    })
  }


};
});
