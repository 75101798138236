import { Controller } from "stimulus"
import {get_user_name, getCookie, setCookie} from '../modules/base_functions'

import {Chat} from '../modules/chat'


export default class extends Controller {
  static targets = [ "output" ]

  connect() {
console.log("load chat")
get_user_name().then((current_user_name) =>{
  console.log(current_user_name)
window.chat = new Chat(current_user_name);
})
  }
}
