
import { Controller } from "stimulus"
//import Chart from 'chart.js/auto';
import Chart from 'chart.js/auto';
import zoomPlugin from 'chartjs-plugin-zoom';
import 'chartjs-adapter-luxon';

Chart.register(zoomPlugin);

export default class extends Controller {
  static targets = [ "output" ]

  connect() {

    console.log("chart controller connected")
    this.say_hello();

}

say_hello(){
  const project_slug = $("[data-projectslug]").data().projectslug;

let chartX, chartY,chartName,chartYLabel

  $.getJSON(`/projects/${project_slug}/api/v1/measurements/index.json`, function(jsonData) {
    document.querySelector('#loading_spin').style.display = "none"
    $.each(jsonData.measurements, function(key, jdata) {
chartX = jdata.times
chartY = jdata.values
chartName = jdata.name
chartYLabel = jdata.labels[0] + " ["+ jdata.units[0]+ "]"
//console.log(chartYLabel)
//console.log(jdata.labels)
var canvas = document.createElement('canvas');
canvas.id     = "Measurment_" + key;
canvas.width  = 400;
canvas.height = 200;

document.querySelector('#meas_charts').appendChild(canvas);
  var ctx = canvas.getContext('2d');
  //const labels = Utils.months({count: 7});
    const x = [...Array(100).keys()];
  //  const labels = [...Array(100).keys()];,
  var labels=[]

    const data = {
      labels: chartX,
      datasets: [{
        label: chartName,
        data: chartY,
        fill: false,
        pointRadius:2,
          //      backgroundColor: 'rgba(75, 192, 192)',
        borderColor: 'rgba(75, 192, 192)',
        tension: 0.1
      }]
    };


  const config = {
  type: 'line',

  data: data,
  options: {
    scales: {
      x: {
        type: 'time',
        position: 'bottom',
        time: {

  displayFormats: {
    'hour': 'DD HH:mm',
    'day': 'DD HH:mm',

  }
}
      },
      y: {

        title: {
          display: true,
          text: chartYLabel
        }

      }
    },
    plugins: {
      zoom: {
        pan:{
          enabled: true,
          mode: 'x'
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true
          },
          mode: 'x',
        }
      }
    }
  }
};




  var myChart = new Chart(ctx, config);
});



});
}


}
