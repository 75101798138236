
import { Controller } from "stimulus"
//import Chart from 'chart.js/auto';
import consumer from "../channels/consumer"

  let project_conv_id


export default class extends Controller {


  connect() {
    console.log("board controller connected")
    this.boardchan = consumer.subscriptions.create({
        channel: "BoardChannel",
        project:
          document.URL.substring(document.URL.lastIndexOf('/') + 1)

        }, {

      connected() {
        console.log("Connected to the room with id:" + document.URL.substring(document.URL.lastIndexOf('/') + 1) );
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {

        ({data} = data);
        //console.log(data.measurement);
          // to improve with multiple rooms. now all data from all projects is handled here..
          // if data.project_id == $("[data-project]").data().project
          // console.log(data)

     

          if (data.message === "true") {
            const m = confirm('Are you sure you want to clear ?');
            if (m) {
              window.ctx_1.clearRect(0, 0, data.w, data.h);
              return $("#canvasimg")[0].style.display = 'none';
            }
          } else if (data.measurement === "true") {
    let selector = "#measurement_" + data.measurement_id
    console.log(selector)
    console.log(data.new_value)

    document.querySelector(selector).textContent = data.new_value ;
          //display unit not working  + ' ' + data.unit
            document.querySelector(selector).style.color = 'green'
            setTimeout(() => {document.querySelector(selector).style.color='black'},2000)


          }
          else if (data.logentry === "true") {
                      document.querySelector("#opslog_entry").classList.remove("change");

                    console.log('we ve got a new log entry!');
                        console.log(data.opslog_entry);
              document.querySelector("#opslog_entry").textContent = '"' + data.opslog_entry + '"'
              document.querySelector("#opslog_entry").style.color='green'
              setTimeout(() => {document.querySelector("#opslog_entry").style.color='black'},2000)
              document.querySelector("#opslog_entry").classList.add("change");
              document.querySelector("#opslog_date").textContent = '- published on ' + data.opslog_date + ', by ' + data.opslog_author

          }

          else {
            window.ctx_1.beginPath();
            window.ctx_1.moveTo(data.prevX, data.prevY);
            window.ctx_1.lineTo(data.currX, data.currY);
            window.ctx_1.strokeStyle = data.strokeStyle;
            window.ctx_1.lineWidth = data.lineWidth;
            window.ctx_1.globalCompositeOperation = data.composite_type;
            return window.ctx_1.stroke();
          }
      },

      draw: function(data) {
        // console.log('from draw')
        // console.log(data)
        return this.perform('draw', {data});
      },

      clear: function(data) {
        return this.perform('clear', {data});
      },

      save_curve_data: function(data) {
        return this.perform('save_curve_data', {data});
      }
    });
    console.log(this)
        console.log(consumer.subscriptions)
        this.whiteboard()
}


disconnect(){
  this.boardchan.unsubscribe();
  console.log("unsubscribe from boardchannel")
}

whiteboard(){

    if ($("[data-project]").length > 0){
      let canvas_base = undefined;
      let canvas_layer1 = undefined;
      window.ctx_1 = undefined;
      let ctx_base = undefined;
      let ctx_1 = undefined;
      let img = undefined;
      let w = undefined;
      let h = undefined;
      let flag = false;
      let prevX = 0;
      let currX = 0;
      let prevY = 0;
      let currY = 0;
      let project_id = 0;
      let project_slug = 0;
      let dot_flag = false;
      let composite_type = 'source-over';
      let x = 'black'; //default color
      let y = 4; //Size of the line.
      let current_curve = [];

      $("#green").on('click', function() { return color(this); });
      $("#blue").on('click', function() { return color(this); });
      $("#red").on('click', function() { return color(this); });
      $("#yellow").on('click', function() { return color(this); });
      $("#orange").on('click', function() { return color(this); });
      $("#black").on('click', function() { return color(this); });
      $("#white").on('click', function() { return color(this); });

      $("#clr").on('click', () => erase());
      $("#save_board").on('click', () => save_board());


      const init = function() {
        project_id = $("[data-project]").data().project;
        project_slug = $("[data-projectslug]").data().projectslug;
        canvas_base = $("#board0")[0];
        canvas_layer1 = $("#board1")[0];
        window.ctx_1 = canvas_layer1.getContext('2d');
        ctx_base = canvas_base.getContext('2d');
        ctx_1 = canvas_layer1.getContext('2d');
        ctx_1.globalAlpha = 0.6;
        img = document.getElementById("whiteboard_pic");
        if (img != null ){

        img.onload = function() {

          const W_scaled =  ctx_base.canvas.width;
          const H_scaled=(W_scaled*img.height)/img.width;
          ctx_base.canvas.height = H_scaled;
          ctx_1.canvas.height = H_scaled;
          document.getElementById('main-content').style.height = `${H_scaled + 20}px`;
          ctx_base.drawImage(img, 0,0,W_scaled,H_scaled);


        };
        }

        // Draw previously save whiteboard data

        $.getJSON(`/projects/${project_slug}.json`, function(jsonData) {
          $.each(jsonData.whiteboard_data, function(key, curvedata) {

            curvedata.forEach(function(data) {
            window.ctx_1.beginPath();
            window.ctx_1.moveTo(data.prevX, data.prevY);
            window.ctx_1.lineTo(data.currX, data.currY);
            window.ctx_1.strokeStyle = data.strokeStyle;
            window.ctx_1.lineWidth = data.lineWidth;
            window.ctx_1.globalCompositeOperation = data.composite_type;
            return window.ctx_1.stroke();
            })
          }


        );


        });

        // };


        w = canvas_layer1.width;
        h = canvas_layer1.height;
        canvas_layer1.addEventListener('mousemove', (function(e) {
          findxy('move', e);
        }), false);
        canvas_layer1.addEventListener('mousedown', (function(e) {
          findxy('down', e);
        }), false);
        canvas_layer1.addEventListener('mouseup', (function(e) {
          findxy('up', e);
        }), false);
        canvas_layer1.addEventListener('mouseout', (function(e) {
          findxy('out', e);
        }), false);
        // React to touch events on the canvas
        canvas_layer1.addEventListener('touchstart', (function(e) {
          findxy('touchstart', e);
        }), false);
        canvas_layer1.addEventListener('touchmove', (function(e) {
          findxy('touchmove', e);
        }), false);

      };
      // Set the sellected color
      var color = function(obj) {
        x = obj.id;
        if (x === 'white') {
          y = 16;
          return composite_type = 'destination-out';
        } else {
          y = 4;
          return composite_type = 'source-over';
        }
      };

      const draw = () => {
        let drawdata = {}
        drawdata = {
        currX,
        currY,
        prevX,
        prevY,
        strokeStyle: x,
        lineWidth: y,
        project_slug,
        composite_type
      }

        this.boardchan.draw(drawdata);
      current_curve.push(drawdata)
    // console.log(current_curve)
  }

      var erase = () => this.boardchan.clear({message: "true", w, h,project_slug});
      // m = confirm("Want to clear")
      // if m
      //   ctx.clearRect(0, 0, w, h)
      //   $("#canvasimg")[0].style.display = "none"

      var save_curve_data = () => {this.boardchan.save_curve_data({message: "savedata", current_curve: current_curve,project_slug: project_slug});
      //
      // console.log("from save")
      //   console.log(current_curve)
        current_curve = [];
    }

      // var dwn = document.getElementById('save_board')
      // https://stackoverflow.com/questions/11112321/how-to-save-canvas-as-png-image
      // Event handler for download
      // if (dwn != null){
      // dwn.onclick = function(){
      function save_board(){

        // https://stackoverflow.com/questions/22710627/tainted-canvases-may-not-be-exported
        if (document.getElementById("whiteboard_pic") != null){

        var my_img = new Image();
        my_img.crossOrigin = "Anonymous";
        my_img.src = document.getElementById("whiteboard_pic").src;
        console.log("hyyyyyyyy",my_img);
        my_img.onload = function() {

            ctx_1.globalCompositeOperation = 'destination-over';
            ctx_1.drawImage(my_img, 0,0,W_scaled,H_scaled);
            // call next step in your code here, f.ex: nextStep();
            canvas_layer1.toBlob(function(blob) {
               // saveAs from FileSaver module

               let today = new Date();
               let dd = today.getDate();
               let mm = today.getMonth()+1;
               let yyyy = today.getFullYear();
               let hh = today.getHours();
               let min = today.getMinutes();


                saveAs(blob, `Shared Whiteboard_${yyyy}/${mm}/${dd}_${hh}-${min}.png`);
            });
        };

          const W_scaled =  ctx_base.canvas.width;
          const H_scaled=(W_scaled*img.height)/img.width;

        }else {

                  const W =  ctx_base.canvas.width;
                  const H=ctx_base.canvas.height;
                  ctx_1.globalAlpha = 1;
          ctx_1.globalCompositeOperation = 'destination-over';
  ctx_1.fillStyle = "#FFFFFF";
          ctx_1.fillRect(0,0,W,H);
          canvas_layer1.toBlob(function(blob) {
             // saveAs from FileSaver module

             let today = new Date();
             let dd = today.getDate();
             let mm = today.getMonth()+1;
             let yyyy = today.getFullYear();
             let hh = today.getHours();
             let min = today.getMinutes();


              saveAs(blob, `Shared Whiteboard_${yyyy}/${mm}/${dd}_${hh}-${min}.png`);
          });
        //  ctx_1.clearRect(0,0,W,H);
          ctx_1.globalAlpha = 0.6;
        }


      }


    function getTouchPos(e) {
      if (!e)
          var e = event;

      if(e.touches) {
          if (e.touches.length == 1) { // Only deal with one finger
              var touch = e.touches[0]; // Get the information for finger #1
              touchX=touch.pageX-touch.target.offsetLeft;
              touchY=touch.pageY-touch.target.offsetTop;
          }
      }
  }



      var findxy = function(res, e) {
        if (res === 'down') {



          prevX = currX;
          prevY = currY;
          currX = e.clientX - (canvas_layer1.offsetLeft);
          currY = e.clientY - (canvas_layer1.offsetTop);
          currX = e.clientX - (canvas_layer1.getBoundingClientRect().left);
          currY = e.clientY - (canvas_layer1.getBoundingClientRect().top);
          flag = true;
          dot_flag = true;
          if (dot_flag) {

            // window.ctx.beginPath()
            // window.ctx.fillStyle = x
            // window.ctx.fillRect currX, currY, 2, 2
            // window.ctx.closePath()
            ctx_1.beginPath();
            ctx_1.fillStyle = x;
            ctx_1.fillRect(currX, currY, 2, 2);
            ctx_1.closePath();
            dot_flag = false;
          }

        }
        if (res === 'touchstart') {
          prevX = currX;
          prevY = currY;

              if (!e)
                  var e = event;

              if(e.touches) {
                  if (e.touches.length == 1) { // Only deal with one finger
                      var touch = e.touches[0]; // Get the information for finger #1
                      currX=touch.clientX-canvas_layer1.getBoundingClientRect().left;
                      currY=touch.clientY-canvas_layer1.getBoundingClientRect().top;
                      // console.log("touchXstart:"+ touch.clientX)
                      // console.log("touchYstart:"+ touch.clientY)
                      // console.log("touchXoffsetstart:"+
                      // console.log("touchYoffsetstart:"+ canvas_layer1.getBoundingClientRect().top)
                      // console.log("touchXstart:"+ currX)
                      // console.log("touchYstart:"+ currY)
                  }
              }
              e.preventDefault();

          dot_flag = true;
          if (dot_flag) {

            // window.ctx.beginPath()
            // window.ctx.fillStyle = x
            // window.ctx.fillRect currX, currY, 2, 2
            // window.ctx.closePath()
            ctx_1.beginPath();
            ctx_1.fillStyle = x;
            ctx_1.fillRect(currX, currY, 2, 2);
            ctx_1.closePath();
            dot_flag = false;
          }
        }
        if ((res === 'up') || (res === 'out') || (res === 'touchend')) {
          // current_curve = [];
          if (flag) {
          save_curve_data()
        }
          flag = false;

        }
        if (res === 'move') {
          if (flag) {
            prevX = currX;
            prevY = currY;
            // currX = e.clientX - (canvas_layer1.offsetLeft)
            // currY = e.clientY - (canvas_layer1.offsetTop)
            currX = e.clientX - (canvas_layer1.getBoundingClientRect().left);
            currY = e.clientY - (canvas_layer1.getBoundingClientRect().top);

            return draw();
          }
        }
        if (res === 'touchmove') {

            prevX = currX;
            prevY = currY;
            // currX = e.clientX - (canvas_layer1.offsetLeft)
            // currY = e.clientY - (canvas_layer1.offsetTop)
            if(e.touches) {
                if (e.touches.length == 1) { // Only deal with one finger
                    var touch = e.touches[0]; // Get the information for finger #1
                    // currX=touch.pageX-touch.target.offsetLeft;
                    // currY=touch.pageY-touch.target.offsetTop;
                    currX=touch.clientX-canvas_layer1.getBoundingClientRect().left;
                    currY=touch.clientY-canvas_layer1.getBoundingClientRect().top;
                    // console.log("touchX:"+ currX)
                    // console.log("touchY:"+ currY)
                }
            }
            e.preventDefault();
            return draw();

        }


      };


      //Initialize the board
      return init();
    }
}

}
