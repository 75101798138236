
// call to maps
document.addEventListener("turbolinks:load",() => {

  if ($('#index_map').length > 0){initMap3()}
  if ($('#project_map').length > 0){initMap4()}
//  if ($('#user_map').length > 0){initMap2()}
});



function initMap(lat, lng) {

  var myCoords = new google.maps.LatLng(lat, lng);
  var mapOptions = {
    center: myCoords,
    zoom: 7
  };
  var map = new google.maps.Map(document.getElementById('map'), mapOptions);
  var marker = new google.maps.Marker({
    position: myCoords,
    map: map
  });
}

// map on user page


// map on project page
function initMap4() {
  let infowindow;

  const iconBase = 'https://maps.google.com/mapfiles/kml/shapes/';

  let icons = {
    man: { icon: iconBase + 'man.png'
  },
  ferry: { icon: iconBase + 'ferry.png'
}
};
// icons = {
//   man: { icon: "<%= asset_path('man.png') %>"
// },
//   ferry: { icon: "<%= asset_path('ferry.png') %>"
// }
// };
const center = {
  lat: 0,
  lng: 0
};


const map = new (google.maps.Map)($('#project_map')[0],
// zoom: 2
// center: center

(infowindow = new (google.maps.InfoWindow)));
const bounds = new (google.maps.LatLngBounds);
const project_slug = $("[data-projectslug]").data().projectslug;




return $.getJSON(`/projects/${project_slug}.json`, function(jsonData) {
  $.each(jsonData.participant_locations, function(key, data) {
    let marker;
    // console.log(data.lat);
    const latLng = new (google.maps.LatLng)(data.lat, data.lng);
    bounds.extend(latLng);
    // console.log(bounds.extend(latLng));
    // console.log(data.marker);
    return marker = new (google.maps.Marker)({
      position: latLng,
      map,
      title: data.title,
      icon: icons[data.marker].icon
    });
  });
  $.each(jsonData.project_locations, function(key, data) {

    let marker;
    // console.log(data.lat);
    const latLng = new (google.maps.LatLng)(data.lat, data.lng);
    bounds.extend(latLng);
    // console.log(bounds.extend(latLng));
    // console.log(data.marker);
    return marker = new (google.maps.Marker)({
      position: latLng,
      map,
      title: data.title,
      // icon: icons[data.marker].icon
      icon: {
    path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    scale: 6,
    fillColor: "red",
    fillOpacity: 0.8,
    strokeWeight: 2,
    rotation: parseFloat(data.heading) //this is how to rotate the pointer
}
    });
  });
  map.fitBounds(bounds);
  map.panToBounds(bounds);

  //remove one zoom level to ensure no marker is on the edge.
  map.setZoom(map.getZoom() - 1);
  // set a minimum zoom
  // if you got only 1 marker or all markers are on the same address map will be zoomed too much.
  if (map.getZoom() > 15) {
    return map.setZoom(15);
  }
});
};


// map on location index pag
this.initMap3 = function() {

  const center = {
    lat: 0,
    lng: 0
  };

  const map = new (google.maps.Map)($('#index_map')[0], {
    zoom: 2,
    center
  }
);

const infowindow = new (google.maps.InfoWindow);

return $.getJSON('/locations.json', jsonData => $.each(jsonData, function(key, data) {
  let marker;
  const latLng = new (google.maps.LatLng)(data.lat, data.lng);
  return marker = new (google.maps.Marker)({
    position: latLng,
    map,
    title: data.title
  });
}));
};
