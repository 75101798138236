import { Controller } from "stimulus"

let marker,map,icons

export default class extends Controller {
  static targets = [ "lat", "long", "mark"]

  connect() {
    console.log('hey map')
    this.initMap2()
  }

  refreshMarker(){
    //var lat = document.getElementById('location_latitude').value;
    var lat = this.latTarget.value;
    var lng = this.longTarget.value;
    var myCoords = new google.maps.LatLng(lat, lng);
    var mark = this.markTarget.value;
    marker.setPosition(myCoords);
    marker.setIcon(icons[mark].icon);
    map.setCenter(marker.getPosition());
    document.getElementById('location_source').value = 'manual';
  }

  geolocate() {
    if (window.navigator && window.navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(onGeolocateSuccess, onGeolocateError);
    }

    function onGeolocateSuccess(coordinates) {
      const { latitude, longitude } = coordinates.coords;

      console.log('Received coordinates: ', latitude, longitude);
      document.getElementById('location_latitude').value = latitude;
      document.getElementById('location_longitude').value = longitude;
      document.getElementById('location_source').value = 'browser';
      var myCoords = new google.maps.LatLng(latitude, longitude);
      marker.setPosition(myCoords);
    //  console.log(marker.getPosition())
      map.setCenter(marker.getPosition());
    }

    function onGeolocateError(error) {
      console.warn(error.code, error.message);
      document.getElementById('location_source').value = 'manual';
      if (error.code === 1) {
        console.log('they said no')
      } else if (error.code === 2) {
        // position unavailable
        console.log('position unavailable')
      } else if (error.code === 3) {
        // timeout
        console.log('timeout')
      }
    }

  }

 initMap2() {

  var iconBase = 'https://maps.google.com/mapfiles/kml/shapes/'
  // 'https://developers.google.com/maps/documentation/javascript/examples/full/images/';


  icons = {
    Person: {
      icon: iconBase + 'man.png'
    },
    Boat: {
      icon: iconBase + 'ferry.png'
    }
  };

  var lat = this.latTarget.value;
  var lng = this.longTarget.value;
  if (!lat || !lng){
    lat=59.9;
    lng=10.74;
    this.latTarget.value = lat;
    this.longTarget.value = lng;
  }

  var myCoords = new google.maps.LatLng(lat, lng);
  var mapOptions = {
    center: myCoords,
    zoom: 12
  };
  map = new google.maps.Map(document.getElementById('user_map'), mapOptions);
  var mark = document.getElementById('location_marker').value;

  marker = new google.maps.Marker({

    position: myCoords,
    animation: google.maps.Animation.DROP,
    map: map,
    draggable: true,
    icon: icons[mark].icon,

  });

  this.geolocate()
  marker.addListener('drag', this.markerchanged.bind(this));


  // When drag ends, center (pan) the map on the marker position
  marker.addListener('dragend', function() {
    map.panTo(marker.getPosition());
    document.getElementById('location_source').value = 'manual';
  });

}
markerchanged() {

  let latlng = marker.getPosition();
  let newlat=(Math.round(latlng.lat()*1000000))/1000000;
  let newlng=(Math.round(latlng.lng()*1000000))/1000000;
  // console.log(this.latTarget)
  this.latTarget.value = newlat;
  this.longTarget.value = newlng;

}

}
