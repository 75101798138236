
import {Conversation} from './conversation'


//console.log('ww')
$(document).on('turbolinks:load', function() {

    if ($(".audio-in").length > 0) {

          let urlParams = new URLSearchParams(window.location.search);
          let audio_conv_id = urlParams.get('audio_conv_id');
          let identity = urlParams.get('identity');

          let local_mode = false

      if (!audio_conv_id) {
        audio_conv_id = Math.floor(Math.random() * 100).toString();
      }

      let ws_url = "wss://server0.remotesurvey.live:8080/ws";

  console.log("audio conv id" + audio_conv_id)
  let conversation = new Conversation( ws_url, audio_conv_id, false, identity ),
    videoStreamEl;
    console.log(conversation)
  function updateParticipantsInfo( participantsList ) {
    var nb_participant = document.getElementById("number_of_participants");
    nb_participant.textContent = participantsList.length +1 ;

    let nb_call_participant = document.getElementById("number_of_call_participants");
    let call_participant_number = participantsList.filter(function ( participant ) { return participant.connected;}).length + (conversation.active ? 1 : 0);
    nb_call_participant.textContent = call_participant_number;

    if( call_participant_number ) {
      document.getElementById('call_status').textContent = "active";
    } else {
      document.getElementById('call_status').textContent = "inactive";
    }
  }
  function updateParticipantsList( participantsList ) {
    var activeParticipantsEl = document.getElementById( 'active_participants' ),
      inactiveParticipantsEl = document.getElementById( 'inactive_participants' );
    console.log('hererer');
    activeParticipantsEl.innerHTML = '';
    inactiveParticipantsEl.innerHTML = '';
    participantsList.forEach(function ( participant ) {
      var participantEl = document.createElement('ul');
      participantEl.innerHTML = participant.uuid;
      if( participant.connected ) {
        activeParticipantsEl.appendChild( participantEl );
      } else {
        inactiveParticipantsEl.appendChild( participantEl );
      }
    });
  }
  conversation.onVideoStream = function () {
    videoStreamEl = conversation.createVideoStreamElement();
    document.body.appendChild( videoStreamEl );
  }
  conversation.onActiveChange = function ( active ) {
    if( active ) {
      console.log('I joined the conversation');
      document.getElementById('call_action_label').textContent = "Leave";
    } else {
      if( videoStreamEl ) {
        videoStreamEl.parentNode.removeChild( videoStreamEl );
        console.log('I quitted the conversation');
      }
      document.getElementById('call_action_label').textContent = "Join";
    }

    updateParticipantsInfo( this.participantsList );
    updateParticipantsList( this.participantsList );
  }


  conversation.onUpdatedParticipantList = function ( participantsList ) {
  //nb_participant.textcontent = (participantsList.length+1).to_string();

    console.trace();
    console.log( "******************", participantsList );
    console.log( "number of particpants", participantsList.length +1);

    updateParticipantsInfo( participantsList );
    updateParticipantsList( participantsList );
  }

  setTimeout(function () {
    document.getElementById('call_action_btn').addEventListener('click', function () {
      if( conversation.active ) {
        conversation.stopConversation();
      } else {
        conversation.startConversation();
      }
    });
  });


  conversation.join();
  conversation.startConversation();
  //conversation.setUpParticipantsTracking();

}
});
