// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import {Conversation} from '../modules/conversation'
import {get_user_name, getCookie, setCookie} from '../modules/base_functions'

let conversation,videoStreamEl

    let button = document.querySelector("#join_call");
    let call_icon = document.getElementById("call_icon");
    let mute_icon = document.getElementById("mute_icon");
    // let button_mute = document.querySelector("#mute");
  let project_conv_id
    //var project_conv_id = "wzPy2_8iEcE"
      let joined_call

export default class extends Controller {
  static targets = [ "output" ]

  connect() {

    console.log("audio connect controller")

    var green = "#02B330"
    var red = "#B3181E"
    document.getElementById('number_of_call_participants').style.visibility = "hidden";
    document.getElementById('person_icon').style.visibility = "hidden";
    document.getElementById('call_status').style.color = red;


    project_conv_id = $("[data-projectconvid]").data().projectconvid;


    get_user_name().then((current_user_name) =>{


      current_user_name = current_user_name + "_"+Math.random().toString(36).substring(2, 10)

      conversation = new Conversation("wss://server0.remotesurvey.live:8080/ws",project_conv_id, false,current_user_name);
      conversation.join();
    //  conversation.setUpParticipantsTracking();
  updateParticipantsList( conversation.participantsList );

      conversation.onVideoStream = function () {
        videoStreamEl = conversation.createVideoStreamElement();
        document.body.appendChild( videoStreamEl );
      }
      conversation.onActiveChange = function ( active ) {
        if( active ) {
          // console.log('I joined the conversation');
          document.getElementById('call_action_label').textContent = "Leave";
          document.getElementById('call_icon').textContent = "call_end";
          document.getElementById('call_icon').style.color = red;
        } else {
          if( videoStreamEl ) {
            videoStreamEl.parentNode.removeChild( videoStreamEl );
            // console.log('I quitted the conversation');
          }
          document.getElementById('call_action_label').textContent = "Join";
          document.getElementById('call_icon').textContent = "call";
          document.getElementById('call_icon').style.color = green;
          document.getElementById('mute_icon').textContent = "mic";
        }

        updateParticipantsInfo( this.participantsList );
  			updateParticipantsList( this.participantsList );
      }
      conversation.onUpdatedParticipantList = function ( participantsList ) {
        //nb_participant.textcontent = (participantsList.length+1).to_string();

        console.trace();
        // console.log( "******************", participantsList );
        console.log( "number of particpants", participantsList.length +1);

        updateParticipantsInfo( participantsList );
          updateParticipantsList( participantsList )
      }




joined_call = getCookie("joined_call_"+project_conv_id);
      if (joined_call){
        setCookie("joined_call_"+project_conv_id,"true", 120);
        alert("Welcome back, you will be automatically reconnected to the audio call!")
        conversation.startConversation();
      }

    })

}

  initialize(){
    console.log('audio init controller')
    project_conv_id = $("[data-projectconvid]").data().projectconvid;

    document.getElementById('call_action_btn').addEventListener('click', function () {
      if( conversation.active ) {

        conversation.stopConversation();
        // set cookie value to empty with passed data
        document.cookie = "joined_call_"+project_conv_id+"=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      } else {

        setCookie("joined_call_"+project_conv_id,"true", 120);
        conversation.startConversation();
      }
    });
  let button_mute = document.querySelector("#mute");
    button_mute.addEventListener("click", function() {

      if (mute_icon.textContent == 'mic') {
        // console.log("conv mute")
        conversation.mute();
        mute_icon.textContent = 'mic_off'
      } else {
        // console.log("conv unmute")
        conversation.unmute();
        mute_icon.textContent = 'mic'

      }

    });

  }
  say_hello(){
      this.outputTarget.textContent = 'Hello, hello!'

      conversation_hello.leave();
  }

  disconnect() {

conversation.leave();
    console.log('disconnect audio ')


  //  conversation = null
  }





}


  function updateParticipantsInfo( participantsList ) {
    var green = "#02B330"
    var red = "#B3181E"

    var nb_participant = document.getElementById("number_of_participants");
    // nb_participant.textContent = participantsList.length +1 ;

    var nb_call_participant = document.getElementById("number_of_call_participants");
    var call_participant_number = participantsList.filter(function ( participant ) { return participant.connected;}).length + (conversation.active ? 1 : 0);
    nb_call_participant.textContent = call_participant_number;

    if( call_participant_number ) {
      document.getElementById('call_status').textContent = "active - ";
      document.getElementById('call_status').style.color = green;

      document.getElementById('number_of_call_participants').style.color = green;
      document.getElementById('person_icon').style.color = green;
      document.getElementById('number_of_call_participants').style.visibility = "visible";
      document.getElementById('person_icon').style.visibility = "visible";

    } else {
      document.getElementById('call_status').textContent = "Nobody on call";
      document.getElementById('call_status').style.color = red;
      document.getElementById('number_of_call_participants').style.visibility = "hidden";
      document.getElementById('person_icon').style.visibility = "hidden";
    }
  }

  function updateParticipantsList( participantsList ) {
var activeParticipantsEl = document.getElementById( 'active_participants' );
//  var inactiveParticipantsEl = document.getElementById( 'inactive_participants' );

activeParticipantsEl.innerHTML = '';
// inactiveParticipantsEl.innerHTML = '';
participantsList.forEach(function ( participant ) {
  var participantEl = document.createElement('ul');
  if( participant.connected ) {
        participantEl.innerHTML = participant.uuid.substring(0,participant.uuid.length-9) + ' <i id="user_call_icon" class="material-icons">call</i>';
    activeParticipantsEl.appendChild( participantEl );
  } else {
              participantEl.innerHTML = participant.uuid.substring(0,participant.uuid.length-9) + ' <i id="user_presence_icon" class= "material-icons">stop_circle</i>';
          //    console.log(participantEl);
    activeParticipantsEl.appendChild( participantEl );
  }

});

var participantEl = document.createElement('ul');

  if( conversation.active ) {
        participantEl.innerHTML = conversation.uuid.substring(0,conversation.uuid.length-9) + '<i id="user_call_icon" class="material-icons">call</i>';
        activeParticipantsEl.appendChild( participantEl );
      } else {
                        participantEl.innerHTML = conversation.uuid.substring(0,conversation.uuid.length-9) + ' <i id="user_presence_icon" class= "material-icons">stop_circle</i>';
        activeParticipantsEl.appendChild( participantEl );
      }
}
