//var autobahn = require('autobahn')
//import {autobahn} from './autobahn.js'
var autobahn = require('./autobahn.js');


function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export var RTMPStreamerHubClient = function ( ws_url, rtmp_url, uuid, exploration_id ) {
	this.uuid = uuid;
	this.exploration_id = exploration_id
	this.ws_url = ws_url;
	this.rtmp_url = rtmp_url;
	this.started = false;
}

RTMPStreamerHubClient.prototype = {
	startStreaming: function ( localStream ) {
		var self = this;
		var conversation_websocket = new autobahn.Connection({
 			  url: this.ws_url,
 			  realm: "conversation",
 			  serializers: [new autobahn.serializer.MsgpackSerializer()]
		  });
		conversation_websocket.open();
		this.conversation_websocket = conversation_websocket;
		this.localStream = localStream;
		conversation_websocket.onopen = function ( session ) {
			self.session = session;
			if(self.started) return;
			self.started = true;

			session.call('stream_rtmp', [self.rtmp_url]).then(function () {
				self.initStreamingSession( session, self.localStream );
			});

		}
	},

	pauseStreaming: function () {
		var self = this;
		this.localStreamRecorder.pause();
		this.session.call("pause_stream_rtmp").then(function ( result ) {
			self.session.publish(self.exploration_id + ".rtmp_status", [self.uuid, false]);
		});
	},

	resumeStreaming: function () {
		var self = this;
		this.localStreamRecorder.resume();
		this.session.call("resume_stream_rtmp").then(function ( result ) {
			self.session.publish(self.exploration_id + ".rtmp_status", [self.uuid, true]);
		});
	},

	getRTMPStatus: function () {
		if( this.localStreamRecorder ) {
			if( this.localStreamRecorder.state === 'recording' ) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	},

	updateLocalStream: function ( localStream ) {
		if( this.localStreamRecorder ) {
			if ( this.localStreamRecorder.state === 'recording' ) {
				this.pauseRecording();
				this.localStreamRecorder.stop();
			}
			this.localStreamRecorder = this.newLocalStreamRecorder( this.session, localStream );
		}
		this.localStream = localStream;
	},

	updateRTMPStream: function ( status, localStream ) {
		if( this.localStreamRecorder ) {
			if( this.localStreamRecorder.state === 'recording' ) {
				this.pauseStreaming();
			} else {
				this.resumeStreaming();
			}
		} else {
			this.startStreaming( localStream );
		}
	},

	newLocalStreamRecorder: function ( session, localStream ) {
		var self = this,
			videoBitsPerSecond = conversation_config.videoBitsPerSecond,
			audioBitsPerSecond = conversation_config.audioBitsPerSecond,
			bitsPerSecond = conversation_config.bitsPerSecond;
		var recorderParams = {
			mimeType: 'video/webm;codecs=h264'
		};
		if( videoBitsPerSecond ) { recorderParams.videoBitsPerSecond =  videoBitsPerSecond; }
		if( audioBitsPerSecond ) { recorderParams.audioBitsPerSecond =  audioBitsPerSecond; }
		if( bitsPerSecond ) { recorderParams.bitsPerSecond =  bitsPerSecond; }
		var localStreamRecorder = new MediaRecorder( localStream, recorderParams );
		this.localStreamRecorder = localStreamRecorder;

		localStreamRecorder.addEventListener('dataavailable', function ( e ) {
			//console.log( e.data );
			e.data.arrayBuffer().then(function ( videoArrayBuffer ) {
				return session.call('new_stream_data', [new Uint8Array(videoArrayBuffer)]);
			}).then(function ( result ) {
				 if( !result ) {
					self.pauseStreaming();
				}
			});

		});

		localStreamRecorder.addEventListener('close', function () {
			console.log('closed');
			//self.conversation_websocket.close();
		});

		localStreamRecorder.start(1000);
		return localStreamRecorder;
	},

	initStreamingSession: function ( session, localStream ) {
		this.onStreamingSessionStart( localStream );
		this.localStreamRecorder = this.newLocalStreamRecorder( session, localStream );
		this.session.publish(this.exploration_id + ".rtmp_status", [this.uuid, true]);
	},


	createVideoElementFromStream: function ( stream ) {
      var videoElement = document.createElement('video');
      videoElement.setAttribute('autoplay', '');
      videoElement.setAttribute( 'data-stream-id', stream.id );
      videoElement.srcObject = stream;
      videoElement.muted = true;
      return videoElement;
    },

	onStreamingSessionStart: function ( localStream ) {

	}
}
