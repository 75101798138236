

export async function get_user_name() {



    if ($("[data-userName]").length>0) {
    var current_user_name = $("[data-userName]").data().username

    var current_user_name_unique = getCookie("username_loggedin_unique");

    if (current_user_name_unique != "") {
      // alert("Welcome back " + current_user_name);
    } else {

        setCookie("username_loggedin_unique", current_user_name+"_"+Math.random().toString(36).substring(2, 10), 1*60*24);
    }
    return current_user_name

}
else {
          var current_user_name = getCookie("username");
          if (current_user_name != "") {
            // alert("Welcome back " + current_user_name);
          } else {
            current_user_name = prompt("Please enter your name:", "");

            if (current_user_name != "" && current_user_name != null) {
              setCookie("username", current_user_name, 1*60*24);
            }
            else{
              current_user_name = "Visitor" + Math.floor(Math.random() * 1000).toString();
              alert("Welcome " + current_user_name + ", Refresh the page to enter another name :-)");
            }


          }
        return current_user_name
      }
    }




      export function setCookie(cname, cvalue, exmin) {
        var d = new Date();
        // d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
           d.setTime(d.getTime() + (exmin * 60 * 1000));
        var expires = "expires="+d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
      }

      export function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for(var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
      }
