//var autobahn = require('autobahn')
//import {autobahn} from './autobahn.js'
var autobahn = require('./autobahn.js');

// var RecordingHubClient = require('../app/javascript/modules/recording_hub_client')
import {RecordingHubClient} from './recording_hub_client'
import {RTMPStreamerHubClient} from './rtmp_streamer_hub_client'

import {conversation_config} from './video_in'

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}


export var ConversationHubClient = function ( ws_url, rtmp_url, exploration_id, isVideoStreamer, isLocalStreaming, participantStreamingType, identity, isDisplay ) {
	this.uuid = identity || uuidv4();
	this.ws_url = ws_url;
	this.exploration_id = exploration_id;
	this.isVideoStreamer = isVideoStreamer;
	this.isLocalStreaming = isLocalStreaming;
	this.videoElements = [];
	this.streams = [];
	this.started = false;
	this.isDisplay = isDisplay;

	var participantStreamingType = participantStreamingType === undefined ? (this.RECV | this.SEND) : participantStreamingType;
	this.isActif = !!(participantStreamingType & this.SEND);
	this.isPassif = !!(participantStreamingType & this.RECV);

	this.streamingMode = true;

	this.rtmpStreamerClient = new RTMPStreamerHubClient( ws_url, rtmp_url, this.uuid, this.exploration_id );
	this.recordingHubClient = new RecordingHubClient( ws_url, this.uuid, this.exploration_id );
}

ConversationHubClient.prototype = {
	RECV: 1,
	SEND: 2,

	createSilenceAudioTrack: function () {
		var ctx = new AudioContext(), oscillator = ctx.createOscillator();
  		var dst = oscillator.connect(ctx.createMediaStreamDestination());
  		oscillator.start();
  		return Object.assign(dst.stream.getAudioTracks()[0], {enabled: false});
	},

	createBlackVideoTrack: function () {
		var width = 640,
			height = 480;
  		var canvas = Object.assign(document.createElement("canvas"), {width: width, height: height});
  		canvas.getContext('2d').fillRect(0, 0, width, height);
  		var stream = canvas.captureStream();
  		return Object.assign(stream.getVideoTracks()[0], {enabled: false});
	},


	join: function () {
		var self = this;
		var conversation_websocket = new autobahn.Connection({
 			  url: this.ws_url,
 			  realm: "conversation"
		  });
		conversation_websocket.open();
		this.conversation_websocket = conversation_websocket;

		conversation_websocket.onopen = function ( session ) {
			self.session = session;
			if(self.started) return;
			self.started = true;


			session.subscribe("reset", function () {
				location.reload();
			});

			var icePromise = !self.isLocalStreaming ? session.call('ice_servers', []) :
				new Promise(function ( resolve, reject ) {
					resolve([]);
				});

			icePromise.then(function ( iceServers ) {
				self.iceServers = iceServers;

				if( self.isActif ) {
					var videoParam = conversation_config.video ? conversation_config.video : true;
					var userMediaRequest = self.isVideoStreamer ? {audio: false, video: videoParam} : {audio: true};
					console.log(userMediaRequest);
					var mediaPromise = self.isDisplay ? navigator.mediaDevices.getDisplayMedia({}) : navigator.mediaDevices.getUserMedia(userMediaRequest);
					mediaPromise.then(function( localStream ) {
						var dummyTracks = [self.createBlackVideoTrack(),self.createSilenceAudioTrack()],
							tracksToAdd = [];
						console.log( localStream );
                		self.localStream = localStream;

                		dummyTracks.forEach(function ( dummyTrack ) {
                			var existantTrack = localStream.getTracks().find(function ( track ) {
                				return track.kind === dummyTrack.kind;
                			});

                			if( !existantTrack ) {
                				tracksToAdd.push( dummyTrack );
                			}
                		});
                		tracksToAdd.forEach(function ( trackToAdd ) {
                			localStream.addTrack( trackToAdd );
                		});
               	 		self.initConversationSession( session, localStream );

                		if( self.isVideoStreamer ) {
                  			self.videoStream = localStream;
                  			self.onLocalVideoStream( localStream );
                  			console.log('video stream detected 1');
                		}
                		console.log('connected', self.uuid);
            		});
				} else {
					self.initConversationSession( session, null );
				}

			});
		}
	},

	updateLocalStream: function ( localStream ) {
		var peerConnection = this.peerConnection,
			session = this.session,
			self = this;

		//Add the new localstream tracks to be sent
		localStream.getTracks().forEach(function ( track ) {
			var sender = peerConnection.getSenders().find(function ( sender ) {
				return sender.track && track.kind == sender.track.kind;
			});
			if( sender ) {
				sender.replaceTrack( track );
			}
		});

      	this.recordingHubClient.updateLocalStream( localStream );
      	this.rtmpStreamerClient.updateLocalStream( localStream );

      	this.localStream = localStream;

      	this.onLocalVideoStreamUpdate( localStream );
	},


	leave: function () {
		var self = this;
		this.session.call('leave', [this.uuid]).then(function ( ) {
			self.peerConnection.close();
			self.conversation_websocket.close();
      self.started = false
		});
	},


	initConversationSession: function ( session, localStream ) {
		var self = this;
		var peerConnection = new RTCPeerConnection({iceServers: self.iceServers});
		this.peerConnection = peerConnection;


		peerConnection.onconnectionstatechange = function ( e ) {
        	console.log(  "ice connection state : " + peerConnection.iceConnectionState, "connection state : " + peerConnection.connectionState );
        	if( peerConnection.connectionState === 'failed' ) {
        		self.reconnect();
        	}
      	};

      	session.subscribe(this.exploration_id + '.update_streaming_mode', function ( args ) {
      		self.onRemoteStreamingModeUpdate( args[0], args[1] );
      	});
      	session.register(this.uuid + '.update', function ( onevent ) {
			var operation = onevent[0],
				data = onevent[1];
			if( operation === 'sdp' ) {
				var sessionDescription = new RTCSessionDescription( data.offer );
				console.log('recieve sdp set remote description');
				return peerConnection.setRemoteDescription( sessionDescription ).then(function ( ) {
						return peerConnection.createAnswer();
					}).then(function ( answer ) {
						console.log( answer );
						peerConnection.setLocalDescription(answer);
						self.handleRemoteStreamsUpdate( self.createStreamsFromReceivers( peerConnection.getReceivers(), data.tracks_ids ) );
						return answer;
					});
				console.log('recieved sdp set remote description');
			} else if( operation === 'ice' ) {
				console.log('ice', data);
				if( data ) {
					peerConnection.addIceCandidate( data );
				}
			} else if( operation === 'update_stream_mode' ) {
				self.updateStreamingMode( data );
			} else if( operation === 'update_rtmp_stream' ) {
				self.updateRTMPStream( data );
			} else if( operation === 'start_recording' ) {
				self.startRecording();
			} else if( operation === 'pause_recording' ) {
				self.pauseRecording();
			} else if( operation === 'resume_recording' ) {
				self.resumeRecording();
			} else if( operation === 'upload_recording' ) {
                self.uploadRecording();
            }
		});

		session.register(this.uuid + '.rtmp_status', function ( ) {
			return self.getRTMPStatus();
		});

		session.register(this.uuid + '.recording_status', function ( ) {
			return self.getRecordingStatus();
		});

    	peerConnection.onicecandidate = function ( e ) {
        	session.call( "new_ice", [self.uuid, e.candidate] );
      	};

      	//Fix, reception casess
		if( this.isActif ) {
    		localStream.getTracks().forEach(function(track) {
        		peerConnection.addTrack( track, localStream );
      		});
      		session.call('join', [self.uuid, self.exploration_id, this.isActif, this.isPassif]).then(function ( offerSDP ) {
      			var desc = new RTCSessionDescription({type: "offer", sdp: offerSDP});
      			console.log('1- join set remote description');
      			self.onJoin( session );
          		return peerConnection.setRemoteDescription(desc);
          	}).then(function () {
          		return peerConnection.createAnswer();
			}).then(function ( answer ) {
				return peerConnection.setLocalDescription( answer );
			}).then(function () {
				session.publish(self.uuid + ".sdp_update", [peerConnection.localDescription.sdp]);
				self.handleRemoteStreamsUpdate( self.createStreamsFromReceivers( peerConnection.getReceivers() ) );
			});
		} else {
			session.call('join', [self.uuid, self.exploration_id, this.isActif, this.isPassif]).then(function ( offerSDP ) {
      			var desc = new RTCSessionDescription({type: "offer", sdp: offerSDP});
      			console.log('1- join set remote description');
      			self.onJoin( session );
          		return peerConnection.setRemoteDescription(desc);
          	}).then(function () {
          		console.log('create answer');
          		return peerConnection.createAnswer();
			}).then(function ( answer ) {
				return peerConnection.setLocalDescription( answer );
			}).then(function () {
				session.publish(self.uuid + ".sdp_update", [peerConnection.localDescription.sdp]);
				self.handleRemoteStreamsUpdate( self.createStreamsFromReceivers( peerConnection.getReceivers() ) );
			});
		}
	},

	updateRTMPStream: function ( rtmp_mode ) {
		this.rtmpStreamerClient.updateRTMPStream( rtmp_mode, this.localStream );
	},

	getRTMPStatus: function () {
		return this.rtmpStreamerClient.getRTMPStatus();
	},

	getRecordingStatus: function () {
		return this.recordingHubClient.getRecordingStatus();
	},

	startRecording: function () {
		return this.recordingHubClient.startRecording( this.localStream );
	},

	pauseRecording: function () {
		return this.recordingHubClient.pauseRecording();
	},

	resumeRecording: function () {
		return this.recordingHubClient.resumeRecording();
	},

	uploadRecording: function () {
		return this.recordingHubClient.uploadRecording();
	},

	//TODO: Flexible Updates + separate participate type and streaming mode
	updateStreamingMode: function ( streamingMode ) {
		this.streamingMode = streamingMode;

		var peerConnection = this.peerConnection;
		var localStream = this.localStream;
		var session = this.session;
		var self = this;

		if( streamingMode ) {
			peerConnection.getSenders().forEach(function ( sender ) {
				sender.track.enabled = true;
			});
		} else {
			peerConnection.getSenders().forEach(function ( sender ) {
				sender.track.enabled = false;
			});
		}

		this.session.call("update_streaming_mode", [this.uuid, this.exploration_id, streamingMode]);
	},

	getVideoTrackFromStream: function ( stream ) {
		return stream.getTracks().find(function ( track ) {
			return track.kind === "video";
		});
	},

	createStreamsFromReceivers: function ( receivers, tracks_ids ) {
		var currentTracks = [],
			streams = [];
		if( tracks_ids ) {
			receivers = receivers.filter(function ( receiver ) {
				return !!tracks_ids.find(function ( track_ids ) {
					return track_ids[0] === receiver.track.id || track_ids[1] === receiver.track.id;
				});
			});
		}


		receivers.forEach(function ( receiver ) {
			if( receiver.track.kind === "audio" ) {
				if( currentTracks.length ) {
					var stream = new MediaStream();
					while( currentTracks.length ) {
						var removed_track = currentTracks.shift();
						stream.addTrack( removed_track );
					}
					streams.push( stream );
				}
			}
			currentTracks.push( receiver.track );
		});

		if( currentTracks.length ) {
			var stream = new MediaStream();
			while( currentTracks.length ) {
				var removed_track = currentTracks.shift();
				stream.addTrack( removed_track );
			}
			streams.push( stream );
		}

		streams.map(function ( stream ) {
			console.log("tracks", stream.getTracks());
		})

		return streams;
	},

	equalStreamsFromTracks: function ( stream1, stream2 ) {
		var streamTracks1 = stream1.getTracks(),
			streamTracks2 = stream2.getTracks();
		if( streamTracks1.length !== streamTracks2.length ) {
			return 0;
		} else {
			if( streamTracks1[0].id !== streamTracks1[0].id ) {
				return 0;
			}

			if( streamTracks1[1] && streamTracks2[1] && streamTracks1[1].id !== streamTracks2[1].id ) {
				return 0;
			}

			return 1;
		}
	},

	handleRemoteStreamsUpdate: function ( streams ) {
		var self = this,
			existingStreams = this.streams,
			addedVideoStreams = [],
			removedVideoStreams = [],
			newStreams = [];
        this.videoElements = [];
        console.log("streams", streams);
        streams.forEach(function ( stream ) {
        	var videoElement = document.createElement( 'video' );
        	videoElement.srcObject = stream;
        	videoElement.setAttribute('autoplay', '');
        	var hasvideoTrack = stream.getTracks()
        		.filter(function ( track ) { return track.kind === "video";}).length,
        		isStreamExist = existingStreams.filter(function ( existingStream ) { return self.equalStreamsFromTracks( stream, existingStream ); })
        							 .length;
        	if( !isStreamExist && hasvideoTrack ) {
        		addedVideoStreams.push( stream );
        		self.videoElements.push( videoElement );
    		}
    		if( hasvideoTrack ) {
    			newStreams.push( stream );
    		}
        });
        existingStreams.forEach(function ( existingStream ) {
        	var	isStreamExist = streams.filter(function ( stream ) {return self.equalStreamsFromTracks( stream, existingStream ); })
        							 .length;
        	if( !isStreamExist ) {
        		removedVideoStreams.push( existingStream );
        	}
        });
        this.streams = newStreams;
        this.onRemoteVideoStreamsUpdate( addedVideoStreams, removedVideoStreams );
	},

	mute: function () {
      this.localStream.getTracks().filter(function ( track ) {
        return track.kind === "audio";
      })[0].enabled = false;
    },

    unmute: function () {
      this.localStream.getTracks().filter(function ( track ) {
        return track.kind === "audio";
      })[0].enabled = true;
    },

    createVideoElementFromStream: function ( stream ) {
      var videoElement = document.createElement('video');
      videoElement.setAttribute('autoplay', '');
      videoElement.setAttribute('controls', '');
      videoElement.setAttribute( 'data-stream-id', stream.id );
      videoElement.srcObject = stream;
      if( this.localStream && stream.id === this.localStream.id ) {
        videoElement.muted = true;
      }
      return videoElement;
    },

	onLocalVideoStream: function ( localStream ) {

	},

	onLocalVideoStreamUpdate: function ( localStream ) {

	},

	onRemoteVideoStreamsUpdate: function ( addedVideoStreams, removedVideoStreams ) {

	},

	onRemoteStreamingModeUpdate: function ( uuid, streamingMode ) {

	},

	handleParticipantJoin: function ( handler ) {
		this.session.subscribe( this.exploration_id + '.join', function ( onevent ) {
			handler( onevent[0] );
		});
	},

	handleParticipantLeave: function ( handler ) {
		this.session.subscribe( this.exploration_id + '.leave', function ( onevent ) {
			handler( onevent[0] );
		});
	},

	handleConnectionStateUpdate: function ( handler ) {
		this.session.subscribe( this.exploration_id + '.connection_state', function ( onevent ) {
			handler( onevent[0], onevent[1] );
		});
	},

	handleRTMPStatusUpdate: function ( handler ) {
		console.log( this.exploration_id + '.rtmp_status' );
		this.session.subscribe( this.exploration_id + '.rtmp_status', function ( onevent ) {
			handler( onevent[0], onevent[1] );
		});
	},

	handleRecordingStatusUpdate: function ( handler ) {
		console.log( this.exploration_id + '.rtmp_status' );
		this.session.subscribe( this.exploration_id + '.recording_status', function ( onevent ) {
			handler( onevent[0], onevent[1] );
		});
	},

	onJoin: function ( ) {

	},

	getParticipantsList: function ( ) {
		var uuid = this.uuid;
		return this.session.call('participants_list', [this.exploration_id]).then(function ( participants ) {
			return participants.filter(function ( participant ) { return participant.uuid !== uuid;});
		});
	},

	reconnect: function () {
		location.reload();
	}
}
