var dayjs = require('dayjs')


export class Chat {
  constructor(identity=null) {
    this.channel = null;
    this.client = null;
    this.identity = identity;
    this.messages = ["Connecting..."];
    this.initialize();
  }

  initialize() {
    this.renderMessages();
    $.ajax({
      url: "/tokens",
      type: "POST",
      data: {name: this.identity},
      success: data => {
        console.log("token: ", data);

        this.identity = data.identity;
        this.channel_sid=data.chat_sid

        Twilio.Chat.Client
        .create(data.token)
        .then(client => this.setupClient(client));
      }
    });
  }



  joinChannel() {
    if (this.channel.state.status !== "joined") {
      this.channel.join().then(function(channel) {
  //      console.log("Joined General Channel");
  channel.getMessages().then((messages) => this.add_old_messages(messages))
          console.log('Joined channel ' + channel.friendlyName)
      });
    }
  }

  setupChannel(channel) {
    this.channel = channel;

    // this.joinChannel();
    if (this.channel.state.status !== "joined") {
      this.channel.join().then((channel) =>
      //      console.log("Joined General Channel");
                // console.log('Joined channel :-) ' + channel.friendlyName)
      this.channel.getMessages().then((messages) => this.add_old_messages(messages))
      )
      }


    console.log(this)
    if (this.channel.state.status == "joined") {
    this.channel.getMessages().then((messages) => this.add_old_messages(messages));
  }
    // this is movced here from setupChannel so 'Joined channel ...'' appears after old messages
    this.addMessage({ body: `Joined channel: ${this.channel.friendlyName}, as ${this.identity}` });

    // this.client.on('channelJoined', function(channel) {
    //   console.log('Joined channel xxx ' + channel.friendlyName)
    //   console.log(this)
    //   // this.channel = channel
    //   // this.channel.getMessages().then((messages) => this.add_old_messages(messages));
    // });


    this.channel.on("messageAdded", message => this.addMessage(message));

    this.setupForm();
  }

  add_old_messages(messages){
    const totalMessages = messages.items.length;
    let i=0;
    for (i = 0; i < totalMessages; i++) {
      const message = messages.items[i];

      this.addMessage({ author: message.author, dateUpdated: message.dateUpdated, body:message.body})
    }

  }

  setupClient(client) {
    this.client = client;
    this.client.getChannelBySid(this.channel_sid)
    .then((channel) => this.setupChannel(channel))
    .catch((error) => {
      this.client.createChannel({
        uniqueName: "general",
        friendlyName: "General Chat Channel"
      }).then((channel) => this.setupChannel(channel));
    });
  }

  scroll_bottom() {
    // var objDiv = document.getElementById("messages");
    var objDiv = document.querySelector(".chat .messages");
    objDiv.scrollTop = objDiv.scrollHeight;
  }

  renderMessages() {
    let messageContainer = document.querySelector(".chat .messages");
    messageContainer.innerHTML = this.messages
    .map(message => `<div class="message">${message}</div>`)
    .join("");
    this.scroll_bottom();
  }

  addMessage(message) {
    let html = "";

    if (message.author) {
      const className = message.author == this.identity ? "user me" : "user";
      // console.log(dayjs('2018-04-04T16:00:00.000Z').format('l LT'));
      let a =  dayjs(message.dateUpdated).format("YYYY-MM-DD HH:mm");

      html += `<span class="${className}">${message.author}  [${a}]: </span>`;
    }

    html += message.body;
    this.messages.push(html);

    this.renderMessages();

  }

  setupForm() {
    const form = document.querySelector(".chat form");
    const input = document.querySelector(".chat form input");


    form.addEventListener("submit", event => {
      event.preventDefault();
      this.channel.sendMessage(input.value);
      console.log("message sent:" + input.value )
      console.log(this.channel)
      input.value = "";
      return false;
    });


    document.getElementById('clear_chat').addEventListener('click', event => {
      console.log("removing messages from twilio")
            console.log(this.channel)
this.channel.getMessages().then((messages) => {
var totalMessages = messages.items.length;
let i=0;
for (i = 0; i < totalMessages; i++) {
  messages.items[i].remove()
    console.log("removing 1 messgae")
  }
//  console.log(this.messages)
  this.messages=this.messages.slice(0,2);
  this.renderMessages();


}

    );


  });


};
};
