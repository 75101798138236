import { Controller } from "stimulus"

export default class extends Controller {
  static values = { url: String }

  connect() {
    console.log("Nx stream connect")
    console.log("The URL is:", this.urlValue);

    var video = document.getElementById('video_nx');
	
    if (Hls.isSupported()) {

        var hls = new Hls();
        hls.loadSource(this.urlValue);
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED, function() {
            video.play();
        });
    }
  }

}
