//var autobahn = require('autobahn')
//import {autobahn} from './autobahn.js'
var autobahn = require('./autobahn.js');


function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}


export var Conversation = function ( ws_url, exploration_id, isVideoStreamer, identity ) {
   this.ws_url = ws_url;
   this.exploration_id = exploration_id;
   this.uuid = identity || uuidv4();
   this.peerConnections = {};
   this.videoElements = {};
   this.isVideoStreamer = isVideoStreamer;
   this.active = false;
   this.subscriptions = [];
   this.participantsTrackingSubscriptions = [];
   this.participantsList = [];
}


Conversation.prototype = {
	realm: "conversation",

    join: function () {
    	var self = this;
    	var conversation_websocket = new autobahn.Connection({
 			  url: this.ws_url,
 			  realm: "conversation"
		  });
		  conversation_websocket.open();
		  this.conversation_websocket = conversation_websocket;
      var joinPromise = new Promise(function ( resolve, reject ) {
        conversation_websocket.onopen = function ( session ) {
          self.session = session;
          self.setUpParticipantsTracking();
          resolve( session );
        };
      });
      conversation_websocket.onclose = function () {
  //      self.reconnect();
      }
      this.joinPromise = joinPromise;
		  return joinPromise;
    },

    setUpParticipantsTracking: function () {
      var self = this,
        participantsList = [];
      this.participantsList = participantsList;
      this.joinPromise.then(function ( session ) {
        self.heartBeatCheckInterval = setInterval(function() {
          self.checkHeartBeats();
        }, 5000);

        self.heartBeatNotifInterval = setInterval(function () {
          session.publish( "heartbeat." + self.exploration_id, [self.uuid] );
        }, 10000);

        session.subscribe( "heartbeat." + self.exploration_id, function ( args, kwargs, details) {
          self.updateHeartBeat( args[0], details.publisher );
        });
        session.subscribe( "pong." + self.exploration_id, function ( args, kwargs, details ) {
          console.log('pooooooooooooooooooooooooooong');
          var uuid = args[0],
            isConnected = args[1],
            now_timestamp = Date.now(),
            filteredParticipantsList = participantsList.filter(function ( participant ) { return participant.uuid === uuid;}),
            isParticipantPresent = !!filteredParticipantsList.length;
          if( !isParticipantPresent ) {
            participantsList.push( {uuid: uuid, heartbeat_timestamp: now_timestamp, connected: isConnected, session_id: details.publisher} );
            self.onUpdatedParticipantList( participantsList );
          } else {
            filteredParticipantsList[0].connected = isConnected;
          }
        }).then(function ( subscription ) {
          self.participantsTrackingSubscriptions.push( subscription );
        });
        session.subscribe( "ping." + self.exploration_id, function ( args, kwargs, details ) {
          console.log( "publisher details", details.publisher, self.active );
          var uuid = args[0],
            isConnected = args[1],
            now_timestamp = Date.now(),
            filteredParticipantsList = participantsList.filter(function ( participant ) { return participant.uuid === uuid;}),
            isParticipantPresent = !!filteredParticipantsList.length;
          if( !isParticipantPresent ) {
            participantsList.push( {uuid: uuid, heartbeat_timestamp: now_timestamp, connected: isConnected, session_id: details.publisher} );
            self.onUpdatedParticipantList( participantsList );
          } else {
            filteredParticipantsList[0].connected = isConnected;
          }
          session.publish( "pong." + self.exploration_id, [self.uuid, self.active]);
        }).then(function ( subscription ) {
          self.participantsTrackingSubscriptions.push( subscription );
        });

        session.subscribe( self.exploration_id + ":new_participant", function ( args ) {
          self.addParticipant( args[0] );
        }).then(function ( subscription ) {
          self.participantsTrackingSubscriptions.push( subscription );
        });
        session.subscribe( self.exploration_id + ':leaving_participant', function ( args ) {
          self.disconnectParticipant( args[0] );
        }).then(function ( subscription ) {
          self.participantsTrackingSubscriptions.push( subscription );
        });
        session.subscribe( "wamp.session.on_leave", function ( args ) {
//          console.log('leaved', args);
          self.removeParticipant( args[0] );
        });
        console.log('I am pinging', 'ping.' + self.exploration_id );
        session.publish( 'ping.' + self.exploration_id, [self.uuid, false] );
      });
    },

    checkHeartBeats: function () {
      var self = this,
        participantsList = this.participantsList,
        now_timestamp = Date.now();
      participantsList.forEach(function ( participant ) {
        var heartbeat_timestamp = participant.heartbeat_timestamp,
          session_id = participant.session_id;
        if( now_timestamp - heartbeat_timestamp > 15000 ) {
          self.removeParticipant( session_id );
        }
      });
    },

    updateHeartBeat: function ( uuid, session_id ) {
      var participantsList = this.participantsList,
          filteredParticipantsList = participantsList.filter(function ( participant ) { return participant.uuid === uuid;}),
          isParticipantPresent = !!filteredParticipantsList.length,
          session = this.session;
    //  console.log("session_id", session_id);
      if( isParticipantPresent ) {
        filteredParticipantsList[0].session_id = session_id;
        filteredParticipantsList[0].heartbeat_timestamp = Date.now();
      } else {
        participantsList.push({uuid: uuid, heartbeat_timestamp: Date.now(), connected: false, session_id: session_id});
        if( this.active ) {
          session.publish( this.exploration_id + ":new_participant", [this.uuid, this.isVideoStreamer] );
        } else {
          session.publish( 'ping.' + self.exploration_id, [self.uuid, false] );
        }
        this.onUpdatedParticipantList( participantsList );
      }
    },

    startConversation: function () {
      var self = this;
      return this.joinPromise.then(function ( session ) {
        self.setUpConversation( session );
        self.active = true;
        self.onActiveChange( true );
      });
    },

    addParticipant: function ( uuid ) {
      var participantsList = this.participantsList,
          filteredParticipantsList = participantsList.filter(function ( participant ) { return participant.uuid === uuid;}),
          isParticipantPresent = !!filteredParticipantsList.length;
      if( isParticipantPresent ) {
        filteredParticipantsList[0].connected = true;
        this.onUpdatedParticipantList( participantsList );
      }
    },

    disconnectParticipant: function ( uuid ) {
      var participantsList = this.participantsList,
          filteredParticipantsList = participantsList.filter(function ( participant ) { return participant.uuid === uuid;}),
          isParticipantPresent = !!filteredParticipantsList.length;
      if( isParticipantPresent ) {
        filteredParticipantsList[0].connected = false;
        this.onUpdatedParticipantList( participantsList );
      }
    },

    removeParticipant: function ( sessionId ) {
      var participantsList = this.participantsList,
          filteredParticipantsList = participantsList.filter(function ( participant ) { return participant.session_id === sessionId;}),
          isParticipantPresent = !!filteredParticipantsList.length;
      if( isParticipantPresent ) {
        var participantIndex = participantsList.indexOf( filteredParticipantsList[0] );
        participantsList.splice( participantIndex, 1 );
        this.onUpdatedParticipantList( participantsList );
      }
    },

    setUpConversation: function ( session ) {
      var self = this;
      if( !this.localStream ) {
        session.call('ice_servers',[]).then(function ( iceServers ) {
          self.iceServers = iceServers;
          var userMediaRequest = self.isVideoStreamer ? {audio: true, video: true} : {audio: true};
          navigator.mediaDevices.getUserMedia(userMediaRequest).then(function( localStream ) {
            self.localStream = localStream;
            self.initConversationSession( session, localStream );
            if( self.isVideoStreamer ) {
              self.videoStream = localStream;
              self.onVideoStream();
              console.log('video stream detected 1');
            }
            console.log('connected', self.uuid);
          });
        });
      }
    },

    stopConversation: function () {
      var self = this;
      var session = this.session;
      this.localStream = null;
      this.subscriptions.forEach(function ( subscription ) {
        subscription.unsubscribe( subscription );
      });
      this.subscriptions = [];
      this.session.publish( this.exploration_id + ":leaving_participant", [self.uuid]);
      Object.keys( this.peerConnections ).forEach(function ( participantId ) {
        self.peerConnections[participantId].close();
      });
      this.peerConnections = {};
      this.videoElements = {};
      this.active = false;
      this.onActiveChange( false );
    },

    leave: function () {
      var self = this;


      clearInterval(this.heartBeatNotifInterval);
      clearInterval(this.heartBeatCheckInterval);

      this.conversation_websocket.close('wamp.godbye.normal', 'Good Bye');
    },

    onActiveChange: function ( active ) {},

    onUpdatedParticipantList: function ( participantsList ) {

    },

    mute: function () {
      this.localStream.getTracks().filter(function ( track ) {
        return track.kind === "audio";
      })[0].enabled = false;
    },

    unmute: function () {
      this.localStream.getTracks().filter(function ( track ) {
        return track.kind === "audio";
      })[0].enabled = true;
    },

    initConversationSession: function ( session, localStream ) {
    	var self = this,
        participantsList = this.participantsList;
      session.subscribe( this.exploration_id + ":new_participant", function ( onevent ) {
			 self.handleNewParticipant( onevent[0], localStream, onevent[1] );
		  }).then(function ( subscription ) {
        self.subscriptions.push( subscription );
      });
		  session.subscribe( this.exploration_id + ":peer_sdp", function ( onevent ) {
        if( onevent[2] === self.uuid  ) {
			   self.handlePeerSDP( onevent[0], onevent[1], localStream, onevent[2] );
        }
		  }).then(function ( subscription ) {
        self.subscriptions.push( subscription );
      });
		  session.subscribe( this.exploration_id + ":new_ice_candidate", function ( onevent ) {
        console.log( self.exploration_id + ":new_ice_candidate", onevent[1] );
        if( onevent[1] && onevent[2] === self.uuid ) {
          self.handleNewIceCandidate( onevent[0], onevent[1] );
        }
		  }).then(function ( subscription ) {
        self.subscriptions.push( subscription );
      });
      session.subscribe( this.exploration_id + ":leaving_participant", function ( onevent ) {
        self.handleLeavingParticipant( onevent[0] );
      }).then(function ( subscription ) {
        self.subscriptions.push( subscription );
      });
      session.publish( this.exploration_id + ":new_participant", [self.uuid, self.isVideoStreamer] );
    },


    handleNewIceCandidate: function ( participantId, iceCandidate ) {
    	var peerConnection = this.peerConnections[participantId];
      if( iceCandidate.candidate ) {
        peerConnection.addIceCandidate( iceCandidate ).then(undefined, function ( error ) {
          console.log( error, iceCandidate );
        });
        console.log(iceCandidate.candidate);
      }
    },

    handleNewParticipant: function ( participantId, localStream, isVideoStreamer ) {
      console.log( "new_participant", participantId );
    	var self = this,
        participantsList = this.participantsList,
    		peerConnection = new RTCPeerConnection({iceServers: this.iceServers}),
    		session = this.session,
        filteredParticipantsList = participantsList.filter(function ( participant ) { return participant.uuid === participantId;}),
        isParticipantPresent = !!filteredParticipantsList.length,
        participantPeerConnection = this.peerConnections[participantId];

      if( participantPeerConnection &&
          participantPeerConnection.connectionState &&
          participantPeerConnection.connectionState !== "failed" ) {
        console.log("peer connection working");
        return;
      }
      if( !isParticipantPresent ) {
        participantsList.push({uuid: participantId, heartbeat_timestamp: Date.now(), connected: true});
      } else {
        filteredParticipantsList[0].connected = true;
      }
      this.peerConnections[participantId] = peerConnection;
      var videoElement = document.createElement('video');
    	this.videoElements[participantId] = videoElement;
      peerConnection.onicecandidate = function ( e ) {
        if(e.candidate)
          session.publish( self.exploration_id + ":new_ice_candidate", [self.uuid, e.candidate, participantId] );
      };
      peerConnection.ontrack = function ( e ) {
        //console.log('track 1');
        videoElement.srcObject = e.streams[0];
        videoElement.setAttribute('autoplay', '');

        if( !self.videoStream && e.streams[0].getTracks().length === 2 ) {
          self.videoStream = e.streams[0];
          self.onVideoStream();
          console.log('video stream detected 2');
        }
      };
      peerConnection.onconnectionstatechange = function ( e ) {
        console.log( "participantId : " + participantId, "ice connection state : " + peerConnection.iceConnectionState, "connection state : " + peerConnection.connectionState );

        /*handle leaving participant in case
          failed connection*/
        if( peerConnection.connectionState === 'failed' ) {
          self.handleLeavingParticipant( participantId );
          self.disconnectParticipant( participantId );
        }
      };
      peerConnection.oniceconnectionstatechange = function ( e ) {
        console.log( "participantId : " + participantId, "ice connection state : " + peerConnection.iceConnectionState, "connection state : " + peerConnection.connectionState );

      };
    	peerConnection.onnegotiationneeded = function() {
      		peerConnection.createOffer().then(function( Offer ) {
     			  session.publish( self.exploration_id + ":peer_sdp", [self.uuid, Offer.sdp, participantId, self.isVideoStreamer] );
        		return peerConnection.setLocalDescription(Offer);
      		});
    	};

      localStream.getTracks().forEach(function(track) {
        peerConnection.addTrack( track, localStream );
      });

      this.onUpdatedParticipantList( participantsList );
    },

    handlePeerSDP: function ( participantId, SDP, localStream, isVideoStreamer ) {
    	var peerConnection = this.peerConnections[participantId],
    		session = this.session,
        participantsList = this.participantsList,
        filteredParticipantsList = participantsList.filter(function ( participant ) { return participant.uuid === participantId;}),
        self = this,
    		uuid = this.uuid;

        if( !peerConnection ) {
          var peerConnection = new RTCPeerConnection({iceServers: this.iceServers});
          var videoElement = document.createElement('video');
          this.videoElements[participantId] = videoElement;
          this.peerConnections[participantId] = peerConnection;
          peerConnection.onicecandidate = function ( e ) {
            console.log(' new ice');
            console.log( peerConnection.localDescription.sdp );
            if(e.candidate)
              session.publish( self.exploration_id + ":new_ice_candidate", [self.uuid, e.candidate, participantId] );
          };
          peerConnection.ontrack = function ( e ) {
            console.log('track 2');
            console.log(e.streams[0]);
            videoElement.srcObject = e.streams[0];
            videoElement.setAttribute('autoplay', '');
            if( !self.videoStream && e.streams[0].getTracks().length === 2 ) {
              self.videoStream = e.streams[0];
              self.onVideoStream();
              console.log('video stream detected 3');
            }
          };
          peerConnection.onconnectionstatechange = function ( e ) {
            console.log( "participantId : " + participantId, "ice connection state : " + peerConnection.iceConnectionState, "connection state : " + peerConnection.connectionState );

            /*handle leaving participant in case
              failed connection*/
            if( peerConnection.connectionState === 'failed' ) {
              self.handleLeavingParticipant( participantId );
              self.disconnectParticipant( participantId );
            }
          };
          peerConnection.oniceconnectionstatechange = function ( e ) {
            console.log( "participantId : " + participantId, "ice connection state : " + peerConnection.iceConnectionState, "connection state : " + peerConnection.connectionState );

          };
          this.peerConnections[participantId] = peerConnection;

    	    peerConnection.setRemoteDescription({type: "offer", sdp: SDP}).then(function () {
            localStream.getTracks().forEach(function( track ) {
              peerConnection.addTrack( track, localStream );
            });
          }).then(function () {
            console.log('peer sdp 2');
            return peerConnection.createAnswer();
          }).then(function( answer ) {
            console.log( answer.sdp );
            return peerConnection.setLocalDescription(answer);
          }).then(function() {
            session.publish( self.exploration_id + ":peer_sdp", [self.uuid, peerConnection.localDescription.sdp, participantId] );
      	  });
        } else {
          var desc = new RTCSessionDescription({type: "answer", sdp: SDP});
          peerConnection.setRemoteDescription(desc);
        }

        //Updated connected state if not set
        filteredParticipantsList[0].connected = true;
        this.onUpdatedParticipantList( participantsList );

    },

    handleLeavingParticipant: function ( participantId ) {
      var peerConnection = this.peerConnections[participantId],
        videoElement = this.videoElements[participantId];
      if( peerConnection ) {
        peerConnection.close();
        delete this.peerConnections[participantId];
      }
      delete this.videoElements[participantId];
    },

    createVideoStreamElement: function () {
      var videoElement = document.createElement('video');
      videoElement.setAttribute('autoplay', '');
      videoElement.srcObject = this.videoStream;
      if( this.isVideoStreamer ) {
        videoElement.muted = true;
      }
      return videoElement;
    },

    onVideoStream: function () {

    },

    reconnect: function () {
      var self = this,
        conversation_websocket = this.conversation_websocket,
        currentActive = this.active;
      conversation_websocket.onopen = null;
      conversation_websocket.onclose = null;
      this.videoElements = {};
      Object.keys( this.peerConnections ).forEach(function ( participantId ) {
        self.peerConnections[participantId].close();
      });
      this.peerConnections = {};
      this.videoElements = {};
      this.active = false;
      this.onActiveChange( false );
      clearInterval( this.heartBeatNotifInterval );
      this.join();
      if( currentActive ) {
        this.startConversation();
      }
      conversation_websocket.close();

    }
};
