// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//= require turbolinks

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

//= require jstz
//= require moment-with-locales
//= require moment-timezone-with-data-1970-2030

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// require("popper")

require('bootstrap');
import './bootstrap_custom.js'
// require( './chat.js')
import {Chat} from '../modules/chat'
import {Conversation} from '../modules/conversation'
import jstz from 'jstz';
import flatpickr from "flatpickr";
import { saveAs } from 'file-saver';
require("flatpickr/dist/flatpickr.css")

import {ConversationHubClient} from '../modules/conversation_hub_client'
// import {RecordingHubClient} from '../modules/recording_hub_client'
import Backbone from "backbone"
import Marionette from "backbone.marionette"
import _ from "underscore"

// import {autobahn} from '../modules/autobahn'
// import 'autobahn-bproject_video_idrowser'
// require('autobahn')
//import "../modules/daypilot-modal-2.9";
import '../modules/autobahn'

var dayjs = require('dayjs')


import "../stylesheets/application";
import '../modules/maps'
//import '../modules/boards'
// import '../modules/video_player'
 import '../modules/video_in'
 import '../modules/audio_in'
import '../modules/base_functions'
// import "cocoon";
import "controllers"
//import Chart from 'chart.js/auto';
//require("chart.js")

// require('jQuery')
window.jQuery = $;
window.$ = $;

// require('jstz')
// require('cocoon')
require("@nathanvda/cocoon")

$(document).on('turbolinks:load', function() {



  // extend header margin on landing page in case there is an alert
  if($('.alert').length>0 && document.getElementById('landing')){document.getElementById('landing').style.marginTop = '0px'}

  if ($('#user_time_zone').length > 0){
    $('#user_time_zone')[0].value = jstz.determine().name();
    // console.log(jstz.determine().name())
  }
  if ($('#project_time_zone').length > 0){
    document.getElementById('project_time_zone').value = jstz.determine().name();
    console.log(`hello, your timezone is ${jstz.determine().name()}`)

  }


});


// document.addEventListener("turbolinks:before-visit", function() {
// console.log("conv type:" + typeof conversation)  // ...
// })


document.addEventListener("turbolinks:load",() => {


  if ($('.flatpick').length > 0){

    flatpickr('.flatpick',{
      enableTime: true,
      dateFormat: "Y-m-d H:i",
      altInput: true,
      altFormat: "F j, Y H:i",
      defaultDate: "today",
      time_24hr: true,
    });

    flatpickr('.flatpick2',{
      enableTime: true,
      dateFormat: "Y-m-d H:i",
      altInput: true,
      altFormat: "F j, Y H:i",
      defaultDate: new Date().fp_incr(3),
      time_24hr: true,
    });
  }
  if ($('.flatpick3').length > 0){
    function parseISOString(s) {
      var b = s.split(/\D+/);
      // return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
      return new Date(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]);

    }
    let date_start = $("[data-datestart]").data().datestart
    flatpickr('.flatpick3',{
      enableTime: true,
      dateFormat: "Y-m-d H:i",
      altInput: true,
      altFormat: "F j, Y H:i",
      defaultDate: parseISOString(date_start),
      time_24hr: true,
    });
    let date_end = $("[data-dateend]").data().dateend
    flatpickr('.flatpick4',{
      enableTime: true,
      dateFormat: "Y-m-d H:i",
      altInput: true,
      altFormat: "F j, Y H:i",
      defaultDate: parseISOString(date_end),
      time_24hr: true,
    });
  }
})
function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

function setCookie(cname, cvalue, exmin) {
  var d = new Date();
  // d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
     d.setTime(d.getTime() + (exmin * 60 * 1000));
  var expires = "expires="+d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for(var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}


// mute and on call buttons
//$(document).on('turbolinks:load', function() {

$(document).on('turbolinks:load', function() {



  $('#wb').hover(function() {
    $('#popup').show();
  }, function() {
    $('#popup').hide();
  });
  $('[data-toggle="tooltip"]').tooltip();
  // });


  if ($('#temp_title').length > 0 || $('#projectVideoID').length > 0){
    if ($('#temp_title').length > 0){
      var project_video_id = 'ddddf'
    }
    else {
      var project_video_id = $("[data-projectvideoid]").data().projectvideoid;

    }


      var RemoteStream = Backbone.Model.extend({

    	});

    	var RemoteStreamsCollection = Backbone.Collection.extend({
    		model: RemoteStream
    	});

    	var RemoteStreamView = Marionette.View.extend({


        template: _.template('<div class="container-fluid"><div class="row"><div class="col" data-controldiv-ui><div class="control-ui" data-control-ui><label class="stream-label"> Stream share <span class="stream-number" style="font-size: 25px;"  data-stream-number>1</span></label><span class="fa fa-circle state-icon" data-state-icon></span><button class="start-stop" data-start-stop><span class="fa fa-play"></span></button><span class = "web-stream" data-webstream><label class="stream-label web-stream">Web Stream <span class="stream-number" style="font-size: 25px;"  data-stream-number>0</span></label><span class="fa fa-circle state-icon" data-rtmp-status-icon></span><button class="start-stop" data-start-stop-rtmp><span class="fa fa-play"></span></button></span><label class="stream-label"></label><br></div></div></div><div class="row"><div class="col" data-video-col ><button data-capture-snapshot id="screenshot"><i class="material-icons">add_a_photo</i></button></div></div></div>'),


    		initialize: function () {
    			console.log('initialized');
    			this.model.set('rtmp_status', -1);
    		},

    		ui: {
    		   videoElWrapper: '[data-video-col]',
    		   startStopBtn: '[data-start-stop]',
    		   startStopBtnIcon: '[data-start-stop] span',
    		   stateIcon: '[data-state-icon]',
    		   startStopRTMPBtn: '[data-start-stop-rtmp]',
    		   startStopRTMPBtnIcon: '[data-start-stop-rtmp] span',
    		   rtmpStatusIcon: '[data-rtmp-status-icon]',
    		   startStopRecordingBtn: '[data-start-stop-recording]',
    		   startStopRecordingBtnIcon: '[data-start-stop-recording] span',
    		   recordingStatusIcon: '[data-recording-status-icon]',
    		   uploadRecordingBtn: '[data-upload-recording]',
    		   streamNumber: '[data-stream-number]',
    		   captureBtn: '[data-capture-snapshot]',
           uiWrapper: '[data-control-ui]',
           webstreamWrapper: '[data-webstream]',
           uiDiv: '[data-controldiv-ui]',
    		},

    		events: {
    			'click @ui.startStopBtn': 'startStopStream',
    			'click @ui.startStopRTMPBtn': 'startStopRTMPStream',
    			'click @ui.startStopRecordingBtn': 'startStopRecording',
    			'click @ui.uploadRecordingBtn': 'uploadRecording',
    			'click @ui.captureBtn': 'captureSnapshot'
    		},

    		modelEvents: {
          'change:streamingMode': 'handleStreamingModeUpdate',
    			'change:stream': 'handleStreamUpdate',
    			'change:state': 'setUpStateIcon',
    			'change:rtmp_status': 'setUpRTMPIcons',
    			'change:recording_status': 'setUpRecordingIcons'
    		},

    		collectionEvents: {
    			'add remove reset': 'setUpStreamNumber'
    		},

    		captureSnapshot: function ( e ) {
    			var videoElement = this.ui.videoElWrapper.find('video').get(0);
    			if( !videoElement ) {
    				alert('No video to capture from');
    				return;
    			}
    			var canvas = document.createElement( 'canvas' );
                canvas.width  = videoElement.videoWidth;
                canvas.height = videoElement.videoHeight;
                canvas.getContext('2d').drawImage( videoElement, 0, 0, canvas.width, canvas.height );
                var link = document.createElement( 'a' );
                link.download = 'capture.png';
                canvas.toBlob(function ( blob ) {
                    link.href = URL.createObjectURL( blob );
                    link.click();
                    console.log(link);
                }, 'image/png');
    		},

        handleStreamingModeUpdate: function () {
    			this.setUpStartStopButton();
    		},

        		handleStreamUpdate: function () {
        			this.setUpVideoStreamingEl();
        			this.setUpStateIcon();
        		},
    		uploadRecording: function () {
    			conversationHubClient.session.call(this.model.get('uuid') + '.update', ['upload_recording']);
    			this.ui.uploadRecordingBtn.attr('disabled', '');
    		},

    		setUpStreamNumber: function () {
    			this.ui.streamNumber.text( this.collection.indexOf( this.model ) + 1 );
    		},

        startStopStream: function () {
    			var streamMode = this.model.get('streamingMode') ? 0 : 1;
    			conversationHubClient.session.call(this.model.get('uuid') + '.update', ['update_stream_mode', streamMode]);
    		},

    		startStopRTMPStream: function () {
    			var rtmp_status = this.model.get('rtmp_status');
    			var streamMode = this.model.get('rtmp_status') ? 0 : 1;
    			if( rtmp_status !== -1 ) {
    				conversationHubClient.session.call(this.model.get('uuid') + '.update', ['update_rtmp_stream', streamMode]);
    			}

    		},

    		startStopRecording: function () {
    			var recording_status = this.model.get('recording_status');
    			if( recording_status === -1 ) {
    				conversationHubClient.session.call(this.model.get('uuid') + '.update', ['start_recording']);
    			} else if ( recording_status === 0 ) {
    				conversationHubClient.session.call(this.model.get('uuid') + '.update', ['resume_recording']);
    			} else {
    				conversationHubClient.session.call(this.model.get('uuid') + '.update', ['pause_recording']);
    			}

    		},

    		setUpRecordingIcons: function () {
    			//Setup Recording Status Icon
    			var recording_status = this.model.get( 'recording_status' );
    			var stateIconColorDict = {"1": "red", "0": "yellow", "-1": "black"};
    			this.ui.recordingStatusIcon.css('color', stateIconColorDict[ this.model.get( 'recording_status' ) ] );

    			//Setup Recording StartStop Button Icon
    			if(recording_status === 0  || recording_status === -1 ) {
    				this.ui.startStopRecordingBtnIcon.removeClass('fa-pause fa-question').addClass('fa-play');
    			} else if( recording_status === 1 ) {
    				this.ui.startStopRecordingBtnIcon.removeClass('fa-play fa-question').addClass('fa-pause');
    				this.ui.uploadRecordingBtn.attr('disabled', '');
    			}

    			if(recording_status === -1 || recording_status === 1) {
    				this.ui.uploadRecordingBtn.attr('disabled', '');
    			} else {
    				this.ui.uploadRecordingBtn.attr('disabled', null);
    			}
    		},

    		setUpRTMPIcons: function () {
    			//Setup RTMP Status Icon
    			var rtmp_status = this.model.get( 'rtmp_status' );
    			var stateIconColorDict = {"-1": "yellow", "1": "green", "0": "red"};
    			console.log('rtmp_status', rtmp_status);
    			this.ui.rtmpStatusIcon.css('color', stateIconColorDict[ this.model.get( 'rtmp_status' ) ] );

    			//Setup RTMP StartStop Button Icon
    			if(rtmp_status === 0) {
    				this.ui.startStopRTMPBtnIcon.removeClass('fa-stop fa-question').addClass('fa-play');
    			} else if( rtmp_status === 1 ) {
    				this.ui.startStopRTMPBtnIcon.removeClass('fa-play fa-question').addClass('fa-stop');
    			} else if( rtmp_status === -1) {
    				this.ui.startStopRTMPBtnIcon.removeClass('fa-play fa-play').addClass('fa-question');
    			}
    		},

    		setUpStateIcon: function () {
    			var stateIconColorDict = {connecting: "yellow", connected: "green", disconnected: "yellow", failed: "red"}
    			this.ui.stateIcon.css('color', stateIconColorDict[ this.model.get( 'state' ) ] );
    		},

        setUpStartStopButton: function () {
    			var streamingMode = this.model.get( 'streamingMode' );

    			if(!streamingMode) {
    				this.ui.startStopBtnIcon.removeClass('fa-stop').addClass('fa-play');
    			} else {
    				this.ui.startStopBtnIcon.removeClass('fa-play').addClass('fa-stop');
    			}
    		},

    		setUpVideoStreamingEl: function () {
    			var stream = this.model.get( 'stream' );
    			if( !stream ) {
    				this.ui.videoElWrapper.find('video').remove();
    				return;
    			} else {
    				var videoStreamEl = conversationHubClient.createVideoElementFromStream( stream );
    			//	videoStreamEl.style['max-height'] = '400px';
    				//videoStreamEl.style['position'] = 'absolute';
    				//videoStreamEl.style['right'] = '0px';
    				videoStreamEl.style.width = '100%';
    				videoStreamEl.style.margin = 'auto';
    				videoStreamEl.style.display = 'block';
    				this.ui.videoElWrapper.append( videoStreamEl );
    			}
    		},

    		onRender: function () {
    			console.log('rendered');
    			this.setUpVideoStreamingEl();
          // hide webstream to everybody
          this.ui.webstreamWrapper.css('display','none');

          var isOwner = $("[data-currentUserIsOwner]").data().currentuserisowner;
          // only show and update UI for owner, hide for others
          if (isOwner === true) {

    			this.setUpStartStopButton();
    			this.setUpStateIcon();
    			this.setUpRTMPIcons();
    			this.setUpRecordingIcons();
        }
        else {
//this.ui.uiDiv.removeClass('col-4').addClass('col-1');
this.ui.uiDiv.css('display','none');
        //  this.ui.uiWrapper.css('display','none');

        }
    			this.setUpStreamNumber();
    			this.$el.addClass( 'row' );
    			this.$el.css('margin-bottom', '17px');
    			var self = this;
    			conversationHubClient.session.call(this.model.get('uuid') + '.rtmp_status').then(function ( rtmp_status ) {
    				console.log( "rtmp_status", rtmp_status );
    				self.model.set( 'rtmp_status', Number( rtmp_status )  );
    			});

    			conversationHubClient.session.call(this.model.get('uuid') + '.recording_status').then(function ( recording_status ) {
    				console.log( "recording_status", recording_status );
    				self.model.set( 'recording_status', Number( recording_status )  );
    			});
    		}
    	});

    	var RemoteStreamCollectionView = Marionette.CollectionView.extend({
    		childView: RemoteStreamView,

    		childViewOptions: function () {
    			return {collection: this.collection};
    		},

    		initialize: function () {
    			this.$el.addClass( 'container' );
    		}
    	});


    	//
      var ws_url = "wss://server.remotesurvey.live:8080/ws";
    //  var ws_url = "wss://servertest2.remotesurvey.live:8080/ws";
      var rtmp_url = "";


    	var remoteStreamsCollection = new RemoteStreamsCollection([]);


	var conversationHubClient = new ConversationHubClient( ws_url,  rtmp_url, project_video_id, false, false, ConversationHubClient.prototype.RECV ),
        participants_initiated = false,
    		initial_streams = [];


        conversationHubClient.join();
    		conversationHubClient.onRemoteVideoStreamsUpdate = function ( addedVideoStreams, removedVideoStreams ) {

    			/*remoteStreamsCollection.add( addedVideoStreams.map(function ( stream ) { return {stream: stream}; }) );*/
          console.log("video streams", addedVideoStreams);

    			if(!participants_initiated) {
    				initial_streams = initial_streams.concat(addedVideoStreams);
    			}

    			addedVideoStreams.forEach(function ( videoStream ) {
    				console.log(conversationHubClient.getVideoTrackFromStream( videoStream ), "videoTrack");
    				var remoteStream = remoteStreamsCollection.find(function ( remoteStream ) { return conversationHubClient.getVideoTrackFromStream( videoStream ).id === remoteStream.get('videoTrackId')})
    				if( remoteStream ) {
    					console.log("remote_stream_found*******************")
    					remoteStream.set('stream', videoStream);
    				}

    			});


          removedVideoStreams.forEach(function ( videoStream ) {
    				var remoteStream = remoteStreamsCollection.find(function ( remoteStream ) { return conversationHubClient.getVideoTrackFromStream( videoStream ).id === remoteStream.get('videoTrackId')});
    				if( remoteStream ) {
    					remoteStream.set('stream', null);
    				}
    			});


    			/*remoteStreamsCollection.remove(removedVideoStreams.map(function ( removedStream ) { return remoteStreamsCollection.filter(function ( remoteStream ) { return remoteStream.get('stream') === removedStream;})[0]; }));*/

    		}

        conversationHubClient.onRemoteStreamingModeUpdate = function ( uuid, streamingMode ) {
      		console.log("streaming mode update", uuid, streamingMode);
      		var remoteStream = remoteStreamsCollection.find(function( remoteStream ) { return remoteStream.get( 'uuid' ) === uuid;});
      		if( remoteStream ) {
      			remoteStream.set('streamingMode', streamingMode);
      		}
      	}

        conversationHubClient.onJoin = function () {
      		this.getParticipantsList().then(function ( participants ) {
      			if( !participants_initiated ) {
      				participants.forEach( function ( participant ) {
      					var stream = initial_streams.filter(function ( stream ) { return conversationHubClient.getVideoTrackFromStream( stream ).id === participant.videoTrackId })[0];
      					if( stream ) {
      						participant.stream = stream;
      					}
      				});
      				participants_initiated = true;
      			}
      			remoteStreamsCollection.set( participants.filter(function ( participant ) { return participant.videoTrackId && participant.isActif; }) );
      		});

      		conversationHubClient.handleParticipantJoin(function ( participantData ) {
      			if( participantData.videoTrackId && participantData.isActif ) {
      				remoteStreamsCollection.add( participantData );
      			}
      		});

      		conversationHubClient.handleParticipantLeave(function ( uuid ) {
      			console.log( uuid );
      			remoteStreamsCollection.remove(remoteStreamsCollection.filter(function( remoteStream ) { return remoteStream.get( 'uuid' ) === uuid;}));
      		});

      		conversationHubClient.handleConnectionStateUpdate(function ( uuid, connection_status ) {
      			console.log( uuid, connection_status );
      			var remoteStream = remoteStreamsCollection.find(function( remoteStream ) { return remoteStream.get( 'uuid' ) === uuid;});

      			if( remoteStream ) {
      				remoteStream.set('state', connection_status);
      			}
      		});

      		conversationHubClient.handleRTMPStatusUpdate(function ( uuid, rtmp_status ) {
      			console.log('rtmp_status', uuid,rtmp_status);
      			var remoteStream = remoteStreamsCollection.find(function( remoteStream ) { return remoteStream.get( 'uuid' ) === uuid;});
      			if( remoteStream ) {
      				remoteStream.set('rtmp_status', Number( rtmp_status ));
      			}
      		});


      		conversationHubClient.handleRecordingStatusUpdate(function ( uuid, recording_status ) {
      			console.log('recording_status', uuid,recording_status);
      			var remoteStream = remoteStreamsCollection.find(function( remoteStream ) { return remoteStream.get( 'uuid' ) === uuid;});
      			if( remoteStream ) {
      				remoteStream.set('recording_status', Number( recording_status ));
      			}
      		});
      	}
      var remoteStreamsView = new RemoteStreamCollectionView({collection: remoteStreamsCollection});
      document.getElementById('vids').appendChild(remoteStreamsView.el);
      remoteStreamsView.render();

    }
  });
