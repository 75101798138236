// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import {ConversationHubClient} from '../modules/conversation_hub_client'
import {get_user_name, getCookie, setCookie} from '../modules/base_functions'

let conversationHubClient2, conversationHubClient3

let button_videoshare,button_screenshare,videoshare_icon,screenshare_icon


export default class extends Controller {
  static targets = [ "output" ]

  connect() {
    console.log("video connect controller")




        var project_video_id = $("[data-projectvideoid]").data().projectvideoid;
        console.log(project_video_id)
      var ws_url = "wss://server.remotesurvey.live:8080/ws";
    conversationHubClient2 = new ConversationHubClient( ws_url,  '', project_video_id, true, false, ConversationHubClient.prototype.SEND, undefined,false  );

        conversationHubClient3 = new ConversationHubClient( ws_url,  '', project_video_id, true, false, undefined, undefined,true  );

  }

  initialize(){
    button_videoshare = document.querySelector("#videoshare");
    button_screenshare = document.querySelector("#screenshare");
    videoshare_icon = document.querySelector("#video_icon");
    screenshare_icon = document.querySelector("#screenshare_icon");
            document.getElementById('videoshare').addEventListener('click', function () {
              // console.log(conversationHubClient2)
              // console.log("before:" + conversationHubClient2.started);
              if( conversationHubClient2.started ) {
                console.log("should leave");
                videoshare_icon.textContent = 'video_call'
                conversationHubClient2.leave();
              } else {
                console.log("should join");
                videoshare_icon.textContent = 'videocam_off'
                conversationHubClient2.join();
              }
              console.log("after:" + conversationHubClient2.started)
            });
            //          conversationHubClient2.join();

            document.getElementById('screenshare').addEventListener('click', function () {
              if( conversationHubClient3.started ) {
                console.log("should leave");
                screenshare_icon.textContent = 'screen_share'
                conversationHubClient3.leave();
              } else {
                console.log("should join");
                screenshare_icon.textContent = 'stop_screen_share'
                conversationHubClient3.join();
              }
            });
  }
  say_hello(){
      this.outputTarget.textContent = 'Hello, hello!'
      console.log('qweq')
      conversation_hello.leave();
  }

  disconnect() {
    if( conversationHubClient3.started ) {
      console.log("should leave convhub3");

      conversationHubClient3.leave();
    }

    if( conversationHubClient2.started ) {
      console.log("should leave convhub2");

      conversationHubClient2.leave();
    }
    console.log('disconnect vids')
  //  conversation = null
  }





}
